<template>
  <AdminPanel>
    <v-container>
      <EventList
        v-if="selectedView === 'list'"
        @view-type-click="selectView"
      />
      <EventCalendar
        v-if="selectedView === 'calendar'"
        @view-type-click="selectView"
      />
    </v-container>
  </AdminPanel>
</template>


<script setup>
// @ is an alias to /src
import {ref} from 'vue'
import AdminPanel from '@/components/ui/AdminPanel.vue'
import EventList from '@/components/event_management/EventList.vue'
import EventCalendar from '@/components/event_management/EventCalendar.vue'


let selectedView = ref('list');

const selectView = (value)=> {
	selectedView.value = value;
}





</script>

<style scoped>


</style>


