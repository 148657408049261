<template>
  <AdminPanel>
    <v-container>
      <div class="tab-heading-and-action">
        <div class="title-and-description">
          <h2>Broadcast</h2>
          <p>Here is your Broadcast list</p>
        </div>

        <div class="filter">
          <div class="input-group">
            <fa icon="search" />
            <input type="text" placeholder="Search" v-model="search" />
          </div>

          <fa icon="filter" @click="toggleFilter" />
          <div class="add-broadcast" @click="goToCreateBroadcast">
            <fa icon="plus" /> Create Broadcast
          </div>
        </div>
      </div>

      <div class="filtercard" v-if="showFilter">
        <fa icon="close" class="filter-close-btn" @click="toggleFilter" />
        <div class="filter-action">
          <h3>Filter</h3>
          <div class="reset-btn" @click="clearFilters">
            RESET
          </div>
        </div>
        <div class="dialog-input">
          <flat-pickr v-model="filters.scheduleDate" v-bind="field" :config="dateConfig" placeholder="Schedule Date"></flat-pickr>
              <button
                  type="button"
                  data-toggle
                  class="dialog-input-btn"
                   >
                  <fa icon="calendar" />
                    <span
                     aria-hidden="true"
                     class="sr-only"
                     >Toggle</span>
                    </button>
        </div>
        <div class="dialog-input" >
          <flat-pickr v-model="filters.scheduleTime" v-bind="field" :config="timeConfig" placeholder="Schedule Time"></flat-pickr>
          <button
                  type="button"
                  data-toggle
                  class="dialog-input-btn"
                   >
                  <fa icon="clock" />
                    <span
                     aria-hidden="true"
                     class="sr-only"
                     >Toggle</span>
                    </button>
            </div>

        
      </div>
    </v-container>

    <div v-if="showSidePanel" class="side-panel">
      <div class="view-member-modal">
        <div class="view-modal-action">
          <fa
            icon="arrow-right-long"
            @click="showSidePanel = false"
          />
          <h3>Active Members</h3>
        </div>
      <div class="side-panel-content">
        <div class="detail-row">
          <h5>Title</h5>
          <span>{{ selectedBroadcast.title }}</span>
        </div>
        <div class="detail-row">
          <h5>Recipients</h5>
          <span>{{ selectedBroadcast.recipients }}</span>
        </div>
        <div class="detail-row">
          <h5>Schedule Date</h5>
          <span>{{ selectedBroadcast.schedule_date }}</span>
        </div>
        <div class="detail-row">
          <h5>Schedule Time</h5>
          <span>{{ selectedBroadcast.schedule_time }}</span>
        </div>
      </div>
      <div class="side-panel-actions">
        <button @click="editBroadcast(selectedBroadcast)">Edit Broadcast</button>
        <button @click="removeBroadcast(selectedBroadcast)">Delete</button>
      </div>
      </div>
    </div>

    <div class="v-table">
      <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :search="search"
        :headers="headers"
        :items-length="totalItems"
        :items="serverItems"
        :loading="loading"
        item-value="name"
        @update:options="loadItems"
      >
        <template #[`item.about`]="{ item }">
          <div :class="item.about">
            {{ limitStringLength(item.about, 50) }}
          </div>
        </template>

        <template #[`item.action`]="{ item }">
          <fa icon="eye" class="pointer" @click="viewBroadcast(item)" />
          <fa icon="pencil" class="pointer" @click="editBroadcast(item)" />
          <fa icon="trash" class="pointer" @click="removeBroadcast(item)" />
        </template>
      </v-data-table-server>
    </div>
  </AdminPanel>
</template>

<script>
import AdminPanel from '@/components/ui/AdminPanel.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

const desserts = [
  {
    title: 'Networkology Event time reschedule',
    recipients: 'Information industry',
    schedule_date: '20-Jan-2024',
    schedule_time: '10:00 AM',
    action: 'dd'
  },
  {
    title: 'Networking Nexus',
    recipients: 'Automobile industry',
    schedule_date: '22-Jan-2024',
    schedule_time: '10:00 AM',
    action: 'dd'
  }
]

const FakeAPI = {
  async fetch ({ page, itemsPerPage, sortBy, search, filters }) {
    return new Promise(resolve => {
      setTimeout(() => {
        const start = (page - 1) * itemsPerPage
        const end = start + itemsPerPage
        let items = desserts.slice().filter(item => {
          if (search && !item.title.toLowerCase().includes(search.toLowerCase())) {
            return false
          }
          if (filters.scheduleDate && item.schedule_date !== filters.scheduleDate) {
            return false
          }
          if (filters.scheduleTime && item.schedule_time !== filters.scheduleTime) {
            return false
          }
          return true
        })

        if (sortBy.length) {
          const sortKey = sortBy[0].key
          const sortOrder = sortBy[0].order
          items.sort((a, b) => {
            const aValue = a[sortKey]
            const bValue = b[sortKey]
            return sortOrder === 'desc' ? bValue - aValue : aValue - bValue
          })
        }

        const paginated = items.slice(start, end)

        resolve({ items: paginated, total: items.length })
      }, 500)
    })
  },
}

export default {
  name: 'BroadcastView',
  components: { AdminPanel, flatPickr },
  data: () => ({
    itemsPerPage: 5,
    headers: [
      { title: 'Title', value: 'title' },
      { title: 'Recipients', value: 'recipients' },
      { title: 'Schedule Date', value: 'schedule_date' },
      { title: 'Schedule Time', value: 'schedule_time' },
      { title: 'Action', value: 'action' }
    ],
    serverItems: [],
    loading: false,
    totalItems: 0,
    search: '',
    showFilter: false,
    filters: {
      scheduleDate: null,
      scheduleTime: null
    },
    dateConfig: {
      wrap: true, 
      altInput: true,
      altFormat: "j-M-Y",
      dateFormat: "Y-m-d",
      disableMobile: true
    },
    timeConfig: {
      wrap: true, 
      dateFormat: "H:i",
      noCalendar: true,
      enableTime: true
    },
    showSidePanel: false,
    selectedBroadcast: null
  }),
  methods: {
    loadItems ({ page, itemsPerPage, sortBy }) {
      this.loading = true
      FakeAPI.fetch({
        page,
        itemsPerPage,
        sortBy,
        search: this.search,
        filters: this.filters
      }).then(({ items, total }) => {
        this.serverItems = items
        this.totalItems = total
        this.loading = false
      })
    },
    goToCreateBroadcast() {
      this.$router.push({ name: 'CreateBroadcast' })
    },
    clearFilters() {
      this.filters={
        scheduleDate: null,
        scheduleTime: null
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    },
    viewBroadcast(item) {
      this.selectedBroadcast = item
      this.showSidePanel = true
    },
    closeSidePanel() {
      this.showSidePanel = false
      this.selectedBroadcast = null
    },
  }
}
</script>

<style scoped>
.tab-heading-and-action {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 1px;
}
.title-and-description {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.tab-heading-and-action h2 {
  font-size: 28px;
}
.tab-heading-and-action .fa-filter {
  color: gray;
  margin-left: 12px;
  cursor: pointer;
}
.filter {
  width: max-content;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.input-group {
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  background: #F5F5F5;
  padding: 4px 5px;
}
.input-group .fa-magnifying-glass {
  color: #7E7E7E;
  margin-right: 8px;
  margin-left: 15px;
}
.input-group input {
  outline: unset;
  height: 32px;
}
.add-broadcast {
  border-radius: 5px;
  background: #007CBA;
  color: white;
  padding: 8px 14px;
  margin-left: 16px;
  cursor: pointer;
}
.add-broadcast .fa-plus {
  margin-right: 8px;
}
.v-table{margin-left: 18px; width:97.5%;}
.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th{width: 120px;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 10px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;}
.v-table :deep() tbody tr > td .fa-pencil{color: #007CBA;margin-right: 10px;}
.pointer {
  cursor: pointer;
}
.filtercard {
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  width:260px;
  padding: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)0px 4px 12px;
  right: 230px;
  display: flex;
  flex-direction: column;
  margin-top:-12.5px;

}
.filter-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.filter-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dialog-input{
  width:230px;
  height: 40px;
  padding-left: 0.5rem; 
  margin-bottom: 20px;
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.dialog-input .fa-calendar, .dialog-input .fa-clock{
  color:#B2B2B2;
}
.dialog-input button{
  margin-left: 5px; 
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}


.side-panel{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;z-index: 20000;width:100%;height: 100vh;}
.side-panel .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}

.side-panel-content {
  margin-top: 20px;
  flex-grow: 1;
}


.detail-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: #7E7E7E;
}

.detail-row span {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color:#333;
}


.side-panel-actions {
  margin-top: 180px;
}

.side-panel-actions button {
  display: block;
  width: 200px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 60px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #007CBA;
  cursor: pointer;
}

.side-panel-actions button:last-child {
  background-color: white;
  color:#EC1B1B;
  border: 1px solid #EC1B1B;
}
.reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-right: 130px;cursor:pointer}
</style>
