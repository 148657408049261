<template>
  <div class="logo">
    <img
      src="../assets/images/ccs-logo.png"
      alt="logo"
    >
  </div>
  <v-container>
    <h1>Privacy Policy</h1>
    <p>This Privacy Policy describes how Comorin Consulting Services ("we," "us," or "our") collects, uses, and discloses your personal information through the CCS ConfApp mobile application ("App"). It also describes your choices regarding your information and how you can access and update it.</p>
    <h3>Information We Collect</h3>
    <p>We collect several different types of information for various purposes to provide and improve our App to you.</p> 
    <h3>Information You Provide:</h3>  
    <ul class="list">
      <li><strong>Registration information:</strong> When you register for the conference and use the App, you may provide certain personal information, such as your name, email address, affiliation, and dietary restrictions.</li>
      <li><strong>Optional profile information:</strong> You may also choose to provide additional information to create a profile within the App, such as your biography, interests, and social media handles.</li>
      <li><strong>In-App communication:</strong> When you use features like messaging or chat rooms, we may collect the content of your communications.</li>
    </ul>

    <h3>Information Collected Automatically:</h3>  
    <ul class="list">
      <li><strong>Usage data:</strong> We may collect information about how you use the App, such as the pages or features you access, the time and duration of your use, and the search terms you use.</li>
      <li><strong>Device data:</strong> We may also collect information about your device, such as your device type, operating system, unique device identifier, and IP address.</li>
      
    </ul>

    <h3>Use of Your Information</h3>  
    <p>We use the information we collect to:</p> 
    <ul class="list">
      <li>Provide and operate the App, including allowing you to register for the conference, access event schedules, connect with other attendees, and participate in interactive features.</li>
      <li>Send you important information about the conference, such as schedule updates, speaker information, and announcements.</li>
      <li>Personalize your experience with the App, such as recommending sessions or speakers based on your interests</li>
      <li>Improve the App and develop new features.</li>
      <li>Analyze trends and usage patterns.</li>
      <li>Comply with legal obligations and enforce our policies.</li>
     </ul>

     <h3>Disclosure of Your Information</h3>  
    <p>We may disclose your information to:</p> 
    <ul class="list">
      <li>Service providers who help us operate the App, such as cloud storage providers or analytics providers.</li>
      <li>Law enforcement or other government agencies if we are required by law to do so.</li>
     </ul>

     <h3>Data Retention</h3>  
    <p>We will retain your information for as long as your account is active or as needed to provide you with services. We will also retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p> 
    <ul class="list">
      <li>Service providers who help us operate the App, such as cloud storage providers or analytics providers.</li>
      <li>Law enforcement or other government agencies if we are required by law to do so.</li>
     </ul>

     <h3>Your Choices</h3>  
    <p>You have several choices regarding your information:</p> 
    <ul class="list">
      <li><strong>Access and update your information:</strong> You can access and update your profile information within the App settings.</li>
      <li><strong>Control notifications:</strong> You can control the types of notifications you receive from the App within your device settings.</li>
      <li><strong>Delete your account:</strong> You can request to delete your account by contacting us at [your email address].</li>
     </ul>

     <h3>Security</h3>  
    <p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet or electronic storage system is completely secure, so we cannot guarantee the security of your information.</p> 

    <h3>Children's Privacy</h3>  
    <p>Our App is not directed to children under 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us.</p> 

    <h3>Changes to this Privacy Policy</h3>  
    <p>Our App is not directed to children under 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us.</p> 
    
    <h3>Contact Us</h3>  
    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:conf@comorin.co">conf@comorin.co</a></p> 
     <br>
  </v-container>
</template>




<style scoped>
 .logo img{width: 115px;
    margin: 21px auto 4px;
    display: block;}
h1{font-size: 28px;margin-bottom: 5px;}
 h3{margin-top: 20px;margin-bottom: 5px;font-size: 20px;}
 p{line-height: 25px;font-size: 15px;}
 ul.list{padding-left: 20px;margin-top: 10px; line-height: 32px;margin-left: 24px;}
</style>


