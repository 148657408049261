<template>
  <div
    v-if="visible"
    class="dialog-overlay"
  >
    <div
      class="dialog"
      :style="{ backgroundColor: dialogBg}"
    >
      <div
        class="close-icon"
        @click="cancel"
      >
        <fa icon="close" />
      </div>
      <Form
        ref="form"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <h3 class="dialog-title">
          {{ title }}
        </h3>
     
        <div class="dialog-body">
          <div class="form-row">
            <div class="track-name">
              <label for="trackName">Track Name<span>*</span> </label>
              <Field
                v-model="trackName"
                type="text"
                name="trackName"
                placeholder="Track Name"
              />
              <ErrorMessage name="trackName" />
              <div
                v-if="errMessage!=''"
                class="err-message"
              >
                {{ errMessage }}
              </div>
            </div>
            <div class="form-col-half">
              <label for="colorpicker">Colorpicker</label><br>
              <color-picker
                v-model:pureColor="pureColor"
                format="hex"
                use-type="pure"
                shape="circle"
                :disable-history="true"
              />
              <Field
                v-model="pureColor"
                type="hidden"
                name="pureColor"
              />
              <ErrorMessage name="pureColor" />
            </div>
          </div> 
          
          <div class="dialog-action">
            <button
              class="info"
              type="button"
              @click="reset"
            >
              {{ resetText }}
            </button>
            <button
              :class="buttonBg"
              type="submit"
            >
              {{ confirmText }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';


const emit = defineEmits(['submit', 'reset' , 'cancel']);

const props = defineProps({
	visible: {
		type: Boolean,
		required: true,
		default:false
	},
	dialogBg: {
		type: String,
		required: false,
		default:'#fff'
	},
	title: {
		type: String,
		required: false,
		default:'Are you sure?'
	},
	getTrackName: {
		type: String,
		required: false,
		default:''
	},
	resetText: {
		type: String,
		required: false,
		default:'Reset'
	},
	buttonBg: {
		type: String,
		required: false,
		default:'info'
	},
	confirmText: {
		type: String,
		required: false,
		default:'Confirm'
	}

  
})


const trackName = ref('');
const pureColor = ref('orange');
const errMessage = ref('');
const form = ref(null);
// const validateData = ()=>{
// 	if(trackName.value ==''){
// 		errMessage.value = 'Please enter track name';
// 		return false;
// 	}else{
// 		errMessage.value = '';
// 		return true;
// 	}
	
// }

const schema = yup.object({
	trackName:yup.string().required('Please enter Track name'),
	pureColor:yup.string().required('Please select Track color')
});


watch(() => props.getTrackName, (newValue, oldValue) => {
	// React to prop changes
	console.log('Prop changed:', newValue);
	trackName.value = newValue;
});

const onSubmit = async(data) => {
	console.log("data",data)
	emit('submit',data,form,pureColor);
}

// const resetForm = ()=>{
// 	form.value.resetForm()  
// }



// const submit = (e)=>{
// 	if(!validateData()){
// 		errMessage.value = 'Please enter track name';
// 		return
// 	}
// 	errMessage.value = '';
// 	emit('submit',e);
	
// }
const reset = () =>{
	emit('reset');
}
const cancel = () =>{
	emit('cancel');
}
</script>

<style scoped>
.form-row{display: flex;}
.dialog-title{display: flex;align-items: center;margin-bottom: 6px;}
.dialog-title .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
 .dialog-body{font-size: 16px;font-weight: 500;}  
 .dialog-body input{width: 100%;
    border: 1px solid gray;
    border-radius: 4px;
    height: 40px;
    margin: 3px 2px 0px 2px;
    background: white;
    padding: 12px;} 
    .dialog-body label{color:#007CBA;font-weight: 700;
    font-size: 16px;}
 .dialog-body label span{color: red;}   
.dialog-body .err-message{color: red;font-size: 14px;margin-top: 4px;}   
.dialog-action{display: flex;justify-content: flex-end;margin-top: 12px;}
.dialog-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.danger{background-color: #D92D20;color: white;border-color:#D92D20;}
.success{background-color: white;border: 1px solid #8BC34A;
    color: #8BC34A;}
.info{background-color: #007CBA!important;border: 1px solid #007CBA!important;
    color: white!important;}    
.dialog-action button{padding: 8px 13px;
    border-radius: 9px;
    border: 1px solid;}

    .dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}
.dialog {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 12px;
    width: 498px;
}
.close-icon{position: absolute;right: 19px;top: 15px;cursor: pointer;}


</style>