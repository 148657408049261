<template>
  <AddEvent />

      
  <div class="card-container">
    <div
      class="card"
      :class="{active:selectedInfoTab ==='All Event'}"
      @click="selectInfoCard('All Event')"
    >
      <div class="card-group">
        <fa icon="calendar-day" />
        <div class="count">
          {{ allEvent }}
        </div>
      </div>
      <div class="card-name">
        All Event
      </div>
    </div>
    <div
      class="card"
      :class="{active:selectedInfoTab ==='Active Events'}"
      @click="selectInfoCard('Active Events')"
    >
      <div class="card-group">
        <fa icon="calendar-day" />
        <div class="count">
          {{ activeEvents }}
        </div>
      </div>
      <div class="card-name">
        Active Event {{ name }}
      </div>
    </div>

    <div
      class="card"
      :class="{active:selectedInfoTab ==='Event History'}"
      @click="selectInfoCard('Event History')"
    >
      <div class="card-group">
        <fa icon="calendar-day" />
        <div class="count">
          {{ eventHistory }}
        </div>
      </div>
      <div class="card-name">
        Event History
      </div>
    </div>
 
    
  </div>


  <div class="tab-heading-and-action"> 
    <div class="heading">
      <h3>{{ selectedInfoTab }}</h3>
    </div>
    

    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="searchText"
          type="text"
          placeholder="Search"
        >
      </div>

      <fa
        icon="filter"
        class="filter-btn"
        @click="openFilter"
      />

      <div
        v-if="showFilter === true"
        class="filtercard"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="closeFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div
            class="reset-btn pointer"
            @click="resetFilter"
          >
            RESET
          </div>
        </div>
       

        <div class="input-group">
          <flat-pickr
            v-model="selectedDate"
            :config="dateConfig"
            class="date-form-control"
            placeholder="Date"
            name="selectedDate"
          />
          <div class="input-group-append">
            <button
              type="button"
              colorpicker="colorpickere"
              data-toggle
            >
              <fa icon="calendar" />
              <span
                aria-hidden="true"
                class="sr-only"
              >Toggle</span>
            </button>
          </div>
        </div>

        <select
          id="status"
          v-model="selectedStatus"
          name="status"
        >
          <option value="">
            Status
          </option>
          <option value="upcoming">
            Upcoming
          </option>
          <option value="ongoing">
            Ongoing
          </option>
        </select>
      </div>


      <div
        class="switcher"
        @click="viewTypeClick"
      >
        <img
          src="../../assets/images/calendar-view.svg"
          alt=""
        >
      </div>
    
   
      <div
        class="add-event"
        @click="clickDialog"
      >
        <fa icon="plus" /> Add Event
      </div>
    </div>
  </div>

  <ActiveEvents
    v-if="selectedInfoTab ==='Active Events'"
  />
  <EventHistory v-if="selectedInfoTab ==='Event History'" /> 
  <AllEvent v-if="selectedInfoTab ==='All Event'" />
</template>

<script setup>
import {ref,reactive,provide,onMounted,computed } from 'vue'
import AddEvent from '@/components/event_management/AddEvent.vue'
import ActiveEvents from '@/components/event_management/ActiveEvents.vue'
import EventHistory from '@/components/event_management/EventHistory.vue'
import AllEvent from '@/components/event_management/AllEvent.vue'
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { useStore } from 'vuex';

const emit = defineEmits(['viewTypeClick'])



const searchText = ref('');
const selectedDate = ref('');
const selectedStatus = ref('');
const showFilter = ref(false);

const store = useStore(); 

const activeEvents = computed(() => {
	return store.state.event.activeEvent;
})

const eventHistory = computed(() => {
	return store.state.event.eventHistory;
})

const allEvent = computed(() => {
	return store.state.event.allEvent;
})

const viewTypeClick = () =>{
	emit('viewTypeClick', 'calendar');
}

let selectedInfoTab = ref('All Event');
let openDialog = reactive({'status':false});

provide('openDialog',openDialog)
provide('searchText',searchText)
provide('selectedDate',selectedDate)
provide('selectedStatus',selectedStatus)


const clickDialog = () => {
	store.state.event.showAddEventDialog = true;
}
 
let selectInfoCard = (tabName) => {
	selectedInfoTab.value = tabName;
	searchText.value = '';
	selectedDate.value ='';
	selectedStatus.value = '';
	showFilter.value = false;
  
}

onMounted(()=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/eventCount`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			let eventCounts = response?.data?.data; 
        
			store.state.event.activeEvent = eventCounts?.activeEvents;
			store.state.event.eventHistory = eventCounts?.allEvents; 
			store.state.event.allEvent = eventCounts?.completedEvents;

		})
});

const openFilter = ()=>{
	showFilter.value = true;
}

const	closeFilter = ()=>{
	showFilter.value = false;
}

const	resetFilter= ()=>{
	selectedDate.value ='';
	selectedStatus.value ='';
}
 
</script>

<style scoped>
.pointer{cursor: pointer;}
.card-container{display: flex;justify-content:flex-start;margin-top: 22px;}
   .card{border-radius: 6px;
    border: 1px solid #d3d2d2;
    background-color: #f7f6f6;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    flex-basis:200px;
    margin-left: 13px;
    cursor: pointer;
    
    }
    .card:nth-child(1){margin-left: unset;}
  .card:hover,.card.active{background-color: #007CBA;color:#fff;transition: ease-in-out .8s;}
  .card-group{display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;}
 
.card-name{font-weight: 600;margin-top: 12px;font-size: 14px;text-align: left;}
.card .svg-inline--fa{padding: 8px;border-radius: 50%;font-size: 21px;width: 20px; height: 20px;}
.card:nth-child(1) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(2) .svg-inline--fa{color:#F2881D;background-color: #EFE3D7;}
.card:nth-child(3) .svg-inline--fa{color: #3632D4;background-color: #cbcade;}
.card:nth-child(4) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.card:nth-child(5) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}



.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.heading{text-align: left;}
.tab-heading-and-action h3{font-size: 22px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}


.search-container{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
  .search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
 .search-container input{outline: unset;}


.filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
.filter-btn{position: relative;cursor: pointer;}
.filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
.filtercard select{border: 1px solid #B2B2B2;
    padding: 6px;
    margin-top: 12px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:#B2B2B2;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
  

.filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
.reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;}
.filter-close-btn{width: max-content;
    margin-left: auto;
    display: block;
   cursor: pointer;}

 
 /* datepicker    */
 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;padding: 7px;}
  .input-group-append {
  display: flex;
  align-items: center; 
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
 /* datepicker end */  
 .switcher img{width: 34px;
    margin-top: 7px;
    margin-left: 12px;
    cursor: pointer;}
 .add-event{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-event .fa-plus{margin-right: 8px;}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing:0 15px;
  color:#000;
  font-weight: 600;
}

table  td, th {
  padding: 8px;
}
/* thead > tr {height: 70px;} */
thead > tr > th{border-top: 1px solid #ACACAC; text-align: left;padding: 27px 8px 12px 12px;}



thead > tr > th span{color:red;}
tbody > tr {
height: 85px;
position: relative;
border-radius: 5px;
border: 1px solid #ACACAC;
background-color: #F0FAFF;
box-shadow: 0px 10px 20px 0px rgba(207, 207, 207, 0.25);
}
tbody > tr:first-child{position: relative;}
tbody > tr:first-child::after{position:absolute;content: '';left: 0; background-color:#ACACAC;width: 100%;height: 1px;}
tbody > tr > td{text-align: left;border-top: 1px solid #ACACAC;border-bottom: 1px solid #ACACAC;padding: 14px;}
tbody > tr > td:first-child{border-left: 1px solid #ACACAC;border-radius: 5px 0 0 5px;}
tbody > tr > td:last-child{border-right: 1px solid #ACACAC;border-radius: 0 5px 5px 0;}
tbody > tr > td div{cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 5px;
    font-size: 14px;}

 tbody > tr > td div .fa-up-right-from-square{margin-right: 12px;}


</style>
