<template>
  <div class="bba-bg" />
  <div class="bg-overlay">
    <div class="form-card">
      <div class="logo">
        <img
          src="@/assets/images/ccs-logo.png"
          alt="logo"
        >
      </div>
      <KeepAlive>
        <PhoneLogin v-if="store.state.authentication.phoneLogin" />
      </KeepAlive>
      <OtpVerification v-if="!store.state.authentication.phoneLogin" />
	
      <!-- <PhoneLogin />-->
 
      <!-- <OtpVerification />  -->
    </div> 
  </div>
</template>


<script setup>
// @ is an alias to /src
import PhoneLogin from '@/components/login/PhoneLogin.vue'
import OtpVerification from '@/components/login/OtpVerification.vue'
import { useStore } from 'vuex';
const store = useStore(); 








</script>

<style scoped>
  

   .bba-bg{
    position:absolute;
    background-image: url(@/assets/images/login-bg.jpeg);
    background-size: cover;

    background-repeat: no-repeat;
    width:100%;
    height:100vh;}
    .bg-overlay{

       position: absolute;
       position: absolute;
    background: #fffffe49;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    backdrop-filter: blur(5px);
    display:flex;
    justify-content: center;
    align-items: center;
    }
    .form-card{
        box-shadow: 0px 10px 15px 0px #7C7C7C33;
    border: 1px solid #dddddd;
    border-radius: 12px;
    margin: 25px auto 25px 135px;
    background-color: #ffffff45;
    padding: 17px;
    width: 400px;
    backdrop-filter: blur(12px);
    }
    .form-card h1{color:#007cba;text-align:center;font-size:20px;}
    .logo img{width: 120px;
    margin: 0px auto;
    display: block;}
   

    @media only screen and (max-width: 600px) {
      .form-card{max-width:90%;}
}
</style>


