<template>
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.no_of_registered_members`]="{ item }">
      <div class="gray-box">
        {{ item.no_of_registered_members }}
      </div>
    </template>

    <template #[`item.no_of_visitors`]="{ item }">
      <div class="gray-box">
        {{ item.no_of_visitors }}
      </div>
    </template>

    <template #[`item.status`]="{ item }">
      <div :class="item.status">
        {{ item.status }}
      </div>
    </template>
  
    <template #[`item.action`]="{ item }">
      <router-link :to="'/schedule/'+item.action">
        <fa
          icon="edit"
        />
      </router-link>
      <fa
        icon="pencil"
        class="pointer"
        @click="editEvent(item.action)"
      />
      <fa
        icon="trash"
        class="pointer"
        @click="deleteEventConfirmation(item.action)"
      />
    </template>

    <!-- <template v-slot:tfoot>
      <tr>
        <td>
          <v-text-field v-model="name" hide-details placeholder="Search name..." class="ma-2" density="compact"></v-text-field>
        </td>
        <td>
          <v-text-field
            v-model="calories"
            hide-details
            placeholder="Minimum calories"
            type="number"
            class="ma-2"
            density="compact"
          ></v-text-field>
        </td>
      </tr>
    </template> -->
  </v-data-table-server>


 
  <div class="text-center">
    <!-- <v-btn
      color="primary"
      @click="deleteDialog = true"
    >
      Open Dialog
    </v-btn> -->

    <v-dialog 
      v-model="deleteDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="deleteDialog = false"
        >
          <fa icon="close" />
        </div>
        
          
        <v-card-text>
          <h3> <fa icon="trash-can" /> Are you sure?</h3>
          Are you sure you want to delete this Event? This action 
          cannot be undone.
        </v-card-text>
     
        <v-card-actions class="delete-action">
          <button @click="deleteDialog = false">
            Cancel
          </button>
          <button @click="deleteEvent">
            Delete
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>





<script setup>

import {ref,reactive,watch,provide,inject} from 'vue'
import moment from 'moment';
import axios from 'axios';
import { useStore } from 'vuex';


const searchText = inject('searchText');
const store = useStore(); 
const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'Event Name', value: 'event_name' },
	{ title: 'Start Date', value: 'start_date' },
	{ title: 'End Date', value: 'end_date' },
	{ title: 'Venue', value: 'venue' },
	{ title: 'No of Registered Members', value: 'no_of_registered_members' },
	{ title: 'No. of Visitors', value: 'no_of_visitors' },
	{ title: 'Status', value: 'status' },
	{ title: 'Action', value: 'action' }
]);

let openDialog = reactive({'status':false});

provide('openDialog',openDialog)

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const eventId=ref('');
const mode =  ref('add');
const speakerView = reactive({
	fullName:'',
	designation:'',
	about:''
})

const name= ref('');
const calories= ref('');
const	search= ref('');
const	snackbar=ref(false);
const viewDialog=ref(false);
const deleteDialog=ref(false);
const	text= ref('');
const	timeout= ref(2000);


watch(name, () => {
	search.value = String(Date.now())
})

watch(calories, () => {
	search.value = String(Date.now())
})

watch(searchText, (newValue) => {
	name.value = newValue;
})


const loadItems  = ({ page, itemsPerPage, sortBy })=> {
	offset.value = (page - 1) * itemsPerPage
	console.log("offset",offset.value);
	loading.value = true
      
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/event?limit=${itemsPerPage}&offset=${offset.value}`,{
			params:{
				value:name.value
			}, 
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			serverItems.value = []
			console.log('All EVNT',response.data);
			// serverItems = response.data.data;
			totalItems.value = response.data.totalCount;
			loading.value = false
          
			let memberData = response.data.data;
			memberData.forEach((item,index) => {
				// let fullName =  `${item.title?item.title :''} ${item.firstName?item.firstName :''} ${item.lastName?item.lastName :''}`;
				serverItems.value.push({
					event_name: item.eventName?item.eventName:'-',
					start_date: item.days?formattedDate(item?.days?.[0]?.date):'-',
					end_date: item.days?formattedDate(item?.days?.[item?.days?.length - 1]?.date):'-',
					venue:item.venue,
					no_of_registered_members:item.registeredMemberCount?item.registeredMemberCount:'0',
					no_of_visitors:item.visitorCount?item.visitorCount:'0',
					status:item.status?capitalizedStr(item.status):'-',
					action:item._id?item._id:'-',
				})

			});
			store.state.event.allEvent = response?.data?.totalCount;


		})

        
}

const formattedDate = (d)=>{
	return moment(d).format('DD-MMM-YYYY');
}

const formattedTime = (t)=>{
	return moment(t).format('hh:mm A');
	// return moment.utc(t).format('hh:mm A');
}

const capitalizedStr = (str)=>{
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const deleteEventConfirmation = (id)=>{
	eventId.value = id;
	deleteDialog.value = true;
}


const deleteEvent = ()=>{
	axios
		.delete(`${process.env.VUE_APP_SERVICE_URL}/event/${eventId.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				deleteDialog.value=false;
				viewDialog.value=false;
				search.value = String(Date.now());
				snackbar.value=true;
				text.value = "Deleted Successfully"; 
						
			}
		})
}

const editEvent = (id)=>{
  console.log("id",id)
  store.state.event.eventId = id;
  store.state.event.showAddEventDialog = true;
}

</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 18px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;}  
 .input-group input{outline: unset;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th{width: 120px;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pencil{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;}
.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}

 .view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;z-index: 20000;width:100%;height: 100vh;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 280px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}
  .gray-box{background-color: #EFEFEF;
    padding: 7px 11px;
    width: max-content;
    border-radius: 7px;} 
    .Ongoing,.Upcoming,.Completed{padding: 5px 6px;
    text-align: center;
    font-weight: 800;
    border-radius: 5px;}  
   .Ongoing{
    border: 2px solid #76C8A7;
    color: #76C8A7;
    } 
    .Upcoming{
    border: 2px solid #7381FF;
    color: #7381FF;
    } 
    .Completed{
    border: 2px solid #EA8B8B;
    color: #EA8B8B;
    } 
</style>

