<template>
  <AddEvent />
  <FullCalendar :options="calendarOptions" />

  <v-dialog 
    v-model="eventInfoDialog"
    width="350"
  >
    <v-card class="event-info">
      <div
        class="close-icon"
        @click="eventInfoDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <div>
          <div class="event-heading">
            <div class="event-color" />{{ eventTitle }}
          </div>
          <div class="event-list">
            <div class="event-icon">
              <fa icon="calendar" />
            </div>
            <div class="event-details">
              {{ eventStartDate }} to {{ eventEndDate }}
            </div>
          </div>
          <div class="event-list">
            <div class="event-icon">
              <fa icon="clock" />
            </div>
            <div class="event-details">
              {{ eventStartTime }} to {{ eventEndTime }}
            </div>
          </div>
          <div class="event-list">
            <div class="event-icon">
              <fa icon="map" />
            </div>
            <div class="event-details">
              {{ eventPlace }}
            </div>
          </div>
          <div class="event-list">
            <div class="event-icon">
              <fa icon="location-dot" />
            </div>
            <div class="event-details">
              {{ eventState }}
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script setup>
// @ is an alias to /src
import {ref,reactive ,provide } from 'vue'
import AddEvent from '@/components/event_management/AddEvent.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';
 const emit = defineEmits(['viewTypeClick'])


let eventInfoDialog = ref(false);
let eventTitle = ref('');
let eventStartDate = ref('');
let eventEndDate = ref('');
let eventStartTime = ref('');
let eventEndTime = ref('');
let eventPlace= ref('');
let eventState = ref('');


let openDialog = reactive({'status':false});

 provide('openDialog',openDialog)

 const formattedDate = (d)=>{
      return moment(d).format('DD MMM YYYY');
    };

  const formattedTime = (t)=>{
    return moment(t, 'HH:mm:ss').format('hh:mm A');
  }; 

const calendarOptions =  reactive({
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left:'myAdditionalHeading',
          center: 'title', // Title displayed in the center
          right: 'prev,next swithcher myCustomButton' // Controls on the right side
        },
        views: {
          customTitle: { // Define the custom view
            titleFormat: function() {
              return 'Custom Calendar Title'; // Custom title text
            }
            // Add other custom configurations for this view if needed
          }
        },
        customButtons: {
          myCustomButton: {
            // icon: 'fa-calendar',
            text: '+    Add Event', // Button text
            click: function() {
              // Handle button click event
              openDialog.status = true;
               console.log("openDialog.status",openDialog.status);
              // alert('Custom button clicked!');
              // Perform your action here
            }
            
          },swithcher: {
            text: '---', // Button text
            // click:handleViewTypeClick
            
            click:function() {
           emit('viewTypeClick', 'list');
            }
          },
          myAdditionalHeading: {
              // Using the content property to render additional HTML in the header
              text: 'Active Events',
            }
        },
    //     dateClick: (arg) => {
    //   alert('date click! ' + arg.dateStr)
    // },
     eventClick: function(info) {
      // console.log('event click',info);
      // alert(info.event._def.title);
      // console.log(`Title:${info.event._def.title}`);
      // console.log(`start date:${info.event.startStr}`);
      // console.log(`start date:${info.event.endStr}`);
      // console.log(`start Time:${info.event.start}`);
      // console.log(`start Time:${info.event.end}`);
      // console.log(`Place:${info.event._def.extendedProps.place}`);
      // console.log(`State:${info.event._def.extendedProps.state}`);
      eventInfoDialog.value= true;

      eventTitle.value = info.event._def.title;
      eventStartDate.value = formattedDate(info.event.start);
      eventEndDate.value = formattedDate(info.event.end);
      eventStartTime.value = formattedTime(info.event.end);
      eventEndTime.value = formattedTime(info.event.end);
      eventPlace.value= info.event._def.extendedProps.place;
      eventState.value = info.event._def.extendedProps.state;
     },
        eventDidMount: function(info) {
          // console.log('info',info);
          // var tooltip = new Tooltip(info.el, {
          //   title: info.event.extendedProps.title,
          //   placement: 'top',
          //   trigger: 'hover',
          //   container: 'body'
          // });
        },
        events: [
          { title: 'Industry Insights',
         startStr: '2024-01-29',
         endStr: '2024-01-31',
        start: '2024-01-29',
        end: '2024-01-30',
        place:'Chennai',
        state:'Tamil nadu',
        color: 'purple' },
        { title: 'Networking Nexus',
         startStr: '2024-01-29',
         endStr: '2024-01-31',
        start: '2024-01-15T00:00:00.000Z',
        end: '2024-01-20T00:00:00.000Z',
        place:'Chennai',
        state:'Tamil nadu',
        color: 'pink' }
        ]
      }
    
  )



  
</script>

<style scoped>
:deep() .fc-event{border-radius: 12px!important;padding-left: 12px;}
:deep() .fc-event-title-container{position: relative;cursor: pointer;}
:deep() .fc-event-title-container::after{position: absolute;content: '\22EE';width: 12px;height:12px;
right: 0;top:1px;}

.event-info {position: relative!important;border-radius: 12px!important;}
.event-info .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.event-info .v-card-text h3{display: flex;align-items: center;}
.event-heading{display: flex;align-items: center; color: black;font-weight: 800;margin-bottom: 12px;}
.event-color{width: 19px;height: 15px;background-color: orange;margin-right: 12px;border-radius: 4px;}
.event-list{display: flex;margin-bottom: 12px;}
.event-icon{width: 28px;}

:deep() th {height:62px;
    background: #83B6CF;
    color: #fff;
    font-weight: 400;}
 :deep() th .fc-scrollgrid-sync-inner{margin-top: 15px;}
 :deep() .fc-scrollgrid-sync-table{background-color: #F6F9F9;}
 :deep() .fc-prev-button,:deep() .fc-next-button{background: #E7E7E7;
    border: 1px solid #E7E7E7;}
   :deep() .fc-icon{margin-top: -10px;}
   :deep() .fc-myCustomButton-button, :deep() .fc-myCustomButton-button:hover{background: #007CBA;
    border: 1px solid;padding: 8px 14px;border-radius: 5px;}
   :deep() .fc-myAdditionalHeading-button,:deep() .fc-myAdditionalHeading-button:hover,.fc-myAdditionalHeading-button.active,.fc-myAdditionalHeading-button:focus{border: unset;
    background: unset;
    color: black;
    font-size: 18px;
    font-weight: 600;
    padding-left: unset;
    outline:unset}
   :deep() .fc-swithcher-button, :deep() .fc-swithcher-button:hover,.fc-button-primary:focus{position: relative;background-color: unset;border: unset;outline:unset;}
   :deep() .fc-swithcher-button::after{position: absolute;
    content: '';
    background: url('../../assets/images/list-view.svg');
    left: 3px;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 34px;}

</style>

