<template>
  <v-dialog 
    v-model="showSpeakerDialog"
    width="400"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;">
            Add Speaker
          </h3>
          <div class="form-card">
            <Form
              ref="form"
              :validation-schema="schema"
              @submit="onSubmit"
            >
              <div class="profile-upload">
                <div class="profile_image">
                  <img
                    :src="imageUrl"
                    alt="speaker profile"
                  >
                </div>
                <!-- <Field
                 
                  name="file"
                >
                  <input
                    ref="file"
                    class="pointer"
                    type="file"
                    validateOnChange="true"
                  >
                </Field>
                <ErrorMessage name="file" /> -->
                <input
                  ref="file"
                  name="file"
                  class="pointer"
                  type="file"
                >
              </div>
            
              <div class="form-row">
                <div class="title">
                  <label for="title">Title</label>
                  <Field
                    id="title"
                    v-model="title"
                    name="title"
                    as="select"
                  >
                    <option value="">
                      Title
                    </option>
                    <option value="Mr">
                      Mr
                    </option>
                    <option value="Mrs">
                      Mrs
                    </option>
                    <option value="Ms">
                      Ms
                    </option>
                  </Field>
                  <ErrorMessage name="title" />
                </div>

                <div class="firstName">
                  <label for="firstName">First Name</label>
                  <Field
                    v-model="firstName"
                    type="text"
                    name="firstName"
                  />
                  <ErrorMessage name="firstName" />
                </div>
              </div>



              <div class="form-row">
                <div class="form-col-full">
                  <label
                    for="lastName"
                    style="margin-top: 12px;"
                  >Last Name</label>
                  <Field
                    v-model="lastName"
                    type="text"
                    name="lastName"
                  />
                  <ErrorMessage name="lastName" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <label for="designation">Designation</label>
                  <Field
                    v-model="designation"
                    type="text"
                    name="designation"
                  />
                  <ErrorMessage name="designation" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <label for="about">About</label>
                  <Field
                    v-model="about"
                    name="about"
                    cols="30"
                    rows="3"
                    as="textarea"
                  />
                  <ErrorMessage name="about" />
                </div>
              </div>




              <div class="form-row">
                <div class="form-col-full">
                  <div class="form-actions">
                    <button
                      v-if="mode=='add'"
                      type="button"
                      @click="resetForm"
                    >
                      Reset
                    </button>
                    <button type="submit">
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>

<script setup>
import {ref, inject,provide, watch, onMounted} from 'vue'
import { useStore } from 'vuex';
import 'flatpickr/dist/flatpickr.css';
import { Form, Field, ErrorMessage} from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';

const store = useStore(); 
const emit = defineEmits(['submit'])



let showSpeakerDialog =  ref(false);

let openSpeakerDialog = inject('openSpeakerDialog');
let speakerId = inject('speakerId');
let mode = inject('mode');
// const formSubmitted = reactive({status:false});
// provide('formSubmitted',formSubmitted);

console.log("Inject",openSpeakerDialog);

let file = ref(null);

const form  = ref();
const title = ref('');
const firstName = ref('');
const lastName = ref('');
const designation = ref('');
const about = ref('');

const snackbar= ref(false);
const text= ref('');
const timeout = ref(2000);
const imageUrl = ref('');
const imageName = ref(null);
const responseImageUrl = ref('');
const serverName = ref('');
const tableRefresh = ref(false);
provide('returnTableRefresh',tableRefresh);

watch(openSpeakerDialog, (newValue) => {
	console.log("newValue",newValue.status);
	showSpeakerDialog.value = newValue.status
});


watch(mode, (newValue) => {
	console.log("speakerId",newValue);
	if(newValue=='edit'){
		editSpeaker(speakerId.value);
	}
});








  
onMounted(() => {
	serverName.value = window.location.origin;
	imageUrl.value = require(`@/assets/images/avatar.svg`);
  
	console.log("serverName",serverName.value);
	console.log("mounted");
	// file.value.addEventListener('change',()=>{
		
	// 	console.log('reffile.value',file.value.files[0]);
		
	// 	axios
	// 		.post(`${process.env.VUE_APP_SERVICE_URL}/files`,
	// 			{file:file.value.files[0]},
	// 			{headers:{
	// 				'Authorization': `Bearer ${store.state.authentication.token}`,
	// 				'Content-Type': 'multipart/form-data'
	// 			}})
	// 		.then((response) => {
				
	// 			if(response.status === 200){
	// 				console.log(" file response",response.data.data);
	// 				responseImageUrl.value = response?.data?.data;
	// 				imageName.value = response?.data?.data.split('/profile/')[1];
	// 				console.log("fileName",response?.data?.data.split('/profile/')[1]);
	// 				loadImage() 

	// 			}
	// 		})
	// });
})

  
const loadImage = ()=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/files?name=${imageName.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
				
			if(response.status === 200){
				console.log("IMG FILE",response.data.data);
				imageUrl.value = `data:image/png;base64,${response.data.data}`;
			}
		})
}




const closeDialog = ()=>{
	openSpeakerDialog.status =false;
	showSpeakerDialog.value =false;
	speakerId.value ='';
	mode.value ='add';
	title.value = '';
	firstName.value = '';
	lastName.value = '';
	designation.value = '';
	about.value = '';
	imageUrl.value = require(`@/assets/images/avatar.svg`);
}





const schema = yup.object({
	// file :yup.string().required('Please upload profile '),
	title:yup.string().required('Please select title'),
	firstName: yup.string().required('Please enter first name'),
	lastName:yup.string().required('Please enter last name'),
	designation: yup.string().required('Please enter designation'),
	about: yup.string().required('Please write about yourself')
});



const onSubmit = (data) => {

	console.log("MODE",mode);
	if(mode.value =='edit'){
		axios
			.put(`${process.env.VUE_APP_SERVICE_URL}/speaker/${speakerId.value}`,
				{
					'title': data.title,
					'firstName':data.firstName,
					'lastName':data.lastName ,
					'designation':data.designation,
					'about':data.about,
					'image':responseImageUrl.value,
        
				},
				{headers:{
					'Authorization': `Bearer ${store.state.authentication.token}`,
				}})
			.then((response) => {
				if(response.status === 200){
          
					snackbar.value=true;
					text.value = "Updated Successfully"; 
					form.value.resetForm();
					imageUrl.value = require(`@/assets/images/avatar.svg`);
					closeDialog();
				// formSubmitted.status = true;
				}
			})
	}else{
		axios
			.post(`${process.env.VUE_APP_SERVICE_URL}/speaker`,
				{
					'title': data.title,
					'firstName':data.firstName,
					'lastName':data.lastName ,
					'designation':data.designation,
					'about':data.about,
					'image':responseImageUrl.value,
        
				},
				{headers:{
					'Authorization': `Bearer ${store.state.authentication.token}`,
				}})
			.then((response) => {
				if(response.status === 200){
          
					snackbar.value=true;
					text.value = "Added Successfully"; 
					form.value.resetForm();
					imageUrl.value = require(`@/assets/images/avatar.svg`);
					closeDialog();
				// formSubmitted.status = true;
				}
			})
	}
	
};

const editSpeaker = (id)=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/speaker/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log("edit response",response);
			if(response.status === 200){
				showSpeakerDialog.value = true;
				openSpeakerDialog.status =true;
				let getMemberDetails = response.data.data[0];
        
				// profile image
				responseImageUrl.value = `${getMemberDetails.image?getMemberDetails.image :''}`;
				imageName.value = responseImageUrl.value?responseImageUrl.value.split('/profile/')[1]:'';
				console.log("imageName.value",imageName.value);
				if(imageName.value!=''){
					loadImage()
				}else{
					imageUrl.value = require(`@/assets/images/avatar.svg`);
				}
				// profile image

				title.value = `${getMemberDetails.title?getMemberDetails.title :''}`;
				firstName.value =  `${getMemberDetails.firstName?getMemberDetails.firstName :''}`; 
				lastName.value =  `${getMemberDetails.lastName?getMemberDetails.lastName :''}`; 
				designation.value = getMemberDetails?.designation || '';
				about.value = getMemberDetails?.about || '';

      
			}
		})
}

const resetForm = ()=>{
	// form.value.resetForm();
  emit('submit','success');
	// tableRefresh.status=true;
}

</script>

<style scoped>
.v-container{padding: 0;}
.pointer{cursor: pointer;}
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */
.profile-upload{margin-bottom: 22px;}
.profile_image img{display: block;
    margin: 0 auto 15px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;}
.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='file'] + span, input[type='text'] + span ,select + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
.title{flex-basis: 30%;margin-right: 18px;}
.firstName{flex-basis: 70%;}

.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select,.title select,.firstName input{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}

/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
/* .input-group input{border:unset;outline: unset;} */
/* .flat-pickr{width:100%!important;}  */
/* .flatpickr-input{width: 30%;background-color: #007CBA;} */
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}

select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
</style>
