<template>
  <AdminPanel>
    <v-container>
      <div class="container">
        <div class="title">
          <h3>Master Chapter</h3>
        </div>
        <div class="filter">
          <div class="input-group">
            <fa icon="search" />
            <input type="text" placeholder="Search" v-model="search" />
          </div>
          <fa icon="filter" />
          <div
        class="filtercard"
        v-if="showFilter"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="toggleFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div class="reset-btn" @click="resetFilter">
            RESET
          </div>
        </div>
       
      </div>

          <div class="add-chapter" type="button" @click="openDialog">
            <fa icon="plus" /> Add Chapter
          </div>
        </div>
      </div>
    </v-container>
    <div class="v-table">
      <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :search="search"
        :headers="headers"
        :items-length="totalItems"
        :items="serverItems"
        :loading="loading"
        item-value="name"
        @update:options="loadItems"
      >
        <template #[`item.about`]="{ item }">
          <div :class="item.about">
            {{ limitStringLength(item.about, 50) }}
          </div>
        </template>

        <template #[`item.action`]="{ item }">
          <fa icon="pencil" class="pointer" @click="editChapter(item)" />
          <fa icon="trash" class="pointer" @click="removeChapter(item)" />
        </template>
      </v-data-table-server>
    </div>
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card class="dialog-box">
        <v-card-title class="dialog-title">
          <h3>Add Chapter</h3>
          <v-icon @click="closeDialog" class="close-icon">close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="form-col-full">
                <label for="chapter">Chapter</label>
                <Field
                  name="chapter"
                  type="text"
                  placeholder="Chapter"
                  class="dialog-input"
                  v-model= "newChapter"
                />
                <ErrorMessage name="chapter" />
                <span />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="dialog-btn" text @click="closeDialog">Cancel</v-btn>
          <v-btn class="dialog-btn" @click="addChapter">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </AdminPanel>
</template>

<script>
import AdminPanel from '@/components/ui/AdminPanel.vue';
import { Field, ErrorMessage } from 'vee-validate';

const desserts = [
  {
    chapter: 'Bangalore',
    action: 'dd',
  },
  {
    chapter: 'Nagercoil',
    action: 'dd',
  },
];

const FakeAPI = {
  async fetch({ page, itemsPerPage, sortBy, search }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const start = (page - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        const items = desserts.slice().filter((item) => {
          if (search.name && !item.name.toLowerCase().includes(search.name.toLowerCase())) {
            return false;
          }
          return true;
        });

        if (sortBy.length) {
          const sortKey = sortBy[0].key;
          const sortOrder = sortBy[0].order;
          items.sort((a, b) => {
            const aValue = a[sortKey];
            const bValue = b[sortKey];
            return sortOrder === 'desc' ? bValue - aValue : aValue - bValue;
          });
        }

        const paginated = items.slice(start, end);

        resolve({ items: paginated, total: items.length });
      }, 500);
    });
  },
};

export default {
  name: 'MasterChapterView',
  components: { AdminPanel, Field, ErrorMessage },
  data: () => ({
    itemsPerPage: 5,
    headers: [
      { title: 'Chapter', value: 'chapter', align: 'start' },
      { title: 'Action', value: 'action', align: 'end' },
    ],
    serverItems: [],
    loading: false,
    totalItems: 0,
    search: '',
    dialog: false,
    newChapter: '',
  }),
  methods: {
    loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true;
      FakeAPI.fetch({
        page,
        itemsPerPage,
        sortBy,
        search: { name: this.search },
      }).then(({ items, total }) => {
        this.serverItems = items;
        this.totalItems = total;
        this.loading = false;
      });
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    addChapter() {
      this.closeDialog();
    },
    addChapter() {
      if (this.newChapter.trim()) {
        this.serverItems.push({ chapter: this.newChapter, action: 'dd' });
        this.newChapter = ''; 
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 1px;
}
.title {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.container h3 {
  font-size: 28px;
}
.container .fa-filter {
  color: gray;
  margin-left: 12px;
}
.filter {
  width: max-content;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.input-group {
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  background: #f5f5f5;
  padding: 4px 5px;
}
.input-group .fa-magnifying-glass {
  color: #7E7E7E;
  margin-right: 8px;
  margin-left: 15px;
}
.input-group input {
  outline: unset;
  height: 32px;
}
.add-chapter {
  border-radius: 5px;
  background: #007cba;
  color: white;
  padding: 8px 14px;
  margin-left: 16px;
  cursor: pointer;
}
.add-chapter .fa-plus {
  margin-right: 8px;
}
.v-table {
  margin-left: 18px;
  width: 97.5%;
}
.v-table :deep() thead {
  background-color: #65a4c4;
  color: #fff;
}
.v-table :deep() thead tr > th {
  width: 120px;
}
.v-table :deep() tbody tr > td {
  text-align: start;
}
.v-table :deep() tbody tr:nth-child(even) {
  background-color: #ecf2f6;
  border-top: unset !important;
}
.v-table :deep() tbody tr:nth-child(odd) > td {
  border-bottom: 1px solid #fff;
}
.v-table :deep() tbody tr:nth-child(even) > td {
  border-bottom: 1px solid #ecf2f6;
}
.v-table :deep() tbody tr > td .fa-trash {
  color: #ec1b1b;
}
.v-table :deep() tbody tr > td .fa-pencil {
  color: #007cba;
  margin-right: 10px;
}

.dialog-box {
  padding: 10px;
}

.dialog-input {
  padding: 0.5rem;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  margin-top: 0.5rem;
  font-size: 1rem;
  width: 100%;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  color: #b2b2b2;
  cursor: pointer;
}

.dialog-btn {
  background-color: #136E9B;
  color:white;
  margin-bottom:20px;
  font-size: 0.75rem;
  font-weight: 600;
  margin-right: 15px;
  width:120px;
  text-transform: none;
}
</style>
