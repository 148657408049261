<template>
  <h1>Enter your phone number to log in</h1>
  <h5>We will send you <b data-v-849663fa="">6 digit</b> verification code</h5>
  <Form
    ref="form"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <Field
      v-slot="{field}"
      v-model="phoneNumber"
      name="phoneNumber"
      type="tel"
      @input="phoneNumberChanged"
      @blur="phoneNumberChanged"
    >
      <vue-tel-input
        v-bind="field"
        v-model.trim="field.value"
        :input-options="options"
        @country-changed="countryChanged"
      /> 
    </Field>

    <ErrorMessage
      v-if="isPhoneNumberTouched"
      name="phoneNumber"
    />
      
    

    <button type="submit">
      <fa
        v-show="spinner===true"
        icon="spinner"
        spin
      />  Generate OTP
    </button>    
  </Form>
</template>


<script setup>
import {ref, inject, watch} from 'vue'
import { Form, Field, ErrorMessage} from 'vee-validate';
import { isValidPhoneNumber} from "libphonenumber-js/mobile"; 
import * as yup from 'yup';

import axios from 'axios';
import { useStore } from 'vuex';
const store = useStore(); 
const form =ref(null);  
const text= ref('');
const spinner = ref(false);
const phoneNumber = ref('');
const isPhoneNumberTouched = ref(false);
const dialCode = ref('');



const options = ref({ placeholder: "Phone Number" })
const phoneNumberChanged = () => {
	isPhoneNumberTouched.value = true;
}

const countryChanged = (country) =>{
	console.log("country.dialCode",country.dialCode);
	dialCode.value = country.dialCode;
}

const schema = yup.object({
	phoneNumber:yup.string()
		.test('phone-validation', 'Please enter Number', function(value) {
			if (!value) {
				return false; // Validation failed
			}
			return true;
		})
		.test('invalid-validation', 'Invalid Phone Number', function(value) {
			if (value && !isValidPhoneNumber(value)) {
				return false; // Validation failed
			}
			return true;
		}).nullable(),
	

});




const onSubmit = async (data) => {
	spinner.value = true;
	console.log('data',data);
	let phone = data.phoneNumber.replace(data.phoneNumber.split(' ')[0],'').replace(/\D/g, '');
	try {
		const response = await axios.post(`${process.env.VUE_APP_SERVICE_URL}/mobileLogin`, {
			phone:phone,
			dialCode:'+'+dialCode.value,
			role:'admin'
		});

		if(response.status === 200){
			spinner.value = false;
			store.commit('authentication/setPhoneNumber', `${phone}`);
			store.commit('authentication/setDialCode', `+${dialCode.value}`);
			store.state.authentication.phoneLogin = false;
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Your OTP has been sent successfully',
				color:'success',
				timeout:2000});
		}
	} catch (error) {
		spinner.value = false;
		if (error.response) {
			if(error.response.data.message){
					
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}



};


</script>
<style scoped>
input:focus {
    outline: none; /* Remove the default blue outline */
    box-shadow: none; /* Remove any default box shadow */
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #e5e5e5 inset; /* Change the color here */
}
input:-moz-autofill {
  box-shadow: 0 0 0px 1000px #e5e5e5 inset; /* Change the color here */
}

.input-group{
        display: flex;
        border: 1px solid #B2B2B2;
    border-radius: 5px;
    height: 38px;
    padding: 4px 13px;
    width: 100%;
    margin-top: 19px;
    }
    input{width:100%;}
    input:focus{border:unset;outline:unset;}

    button{background-color: #007cba;
    border-radius: 5px;
    height: 40px;
    padding: 4px 13px;
    width: 100%;
    margin-top: 19px;
    color: white;}
    span{font-size:14px;color:red;}
    .toggle{cursor: pointer;}
    .vue-tel-input{height: 38px;width:100%;border-radius: 5px;}
	.vue-tel-input:focus-within {
    box-shadow: unset;
    border-color: unset;outline:unset;border: 1px solid #bbb;}
    h1{font-size: 22px;color:#153448;font-weight: 600;}
    h5{margin:12px 0px;font-size: 14px;font-weight: 500;}
    h1, h5{text-align: center;}
    
</style>