<template>
  <v-dialog 
    v-model="showSpeakerDialog"
    width="550"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;">
            {{ venueEditMode===true?'Edit':'Add' }}  Venue
          </h3>
          <div class="form-card">
            <Form
              ref="form"
              :validation-schema="schema"
              @submit="onSubmit"
            >
              <div class="form-row">
                <div class="form-col-full">
                  <label
                    for="venueName"
                    style="margin-top: 12px;"
                  >Venue Display Name<span>*</span></label>
                  <Field
                    ref="countryElem"
                    v-model="venueName"
                    type="text"
                    name="venueName"
                    placeholder="Venue Display Name"
                  />
                  <ErrorMessage name="venueName" />
                </div>
              </div>



              <div class="form-row">
                <div class="form-col-half">
                  <label
                    for="addressOne"
                    style="margin-top: 12px;"
                  >Address Line 1<span>*</span></label>
                  <Field
                    v-model="addressOne"
                    type="text"
                    name="addressOne"
                    placeholder="Address Line 1"
                  />
                  <ErrorMessage name="addressOne" />
                </div>

                <div class="form-col-half">
                  <label
                    for="addressTwo"
                    style="margin-top: 12px;"
                  >Address Line 2<span>*</span></label>
                  <Field
                    v-model="addressTwo"
                    type="text"
                    name="addressTwo"
                    placeholder="Address Line 2"
                  />
                  <ErrorMessage name="addressTwo" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-half">
                  <label
                    for="country"
                    style="margin-top: 12px;"
                  >Country<span>*</span></label>
                  

                  <Field
                    id="country"
                   
                    v-model="country"
                    name="country"
                    as="select" 
                    @change="selectCountry"
                  >
                    <option
                      value=""
                      data-isoCode=""
                    >
                      Select Country
                    </option>

                    <option
                      v-for="country in countries"
                      :key="country.name"
                      :value="country.name"
                      :data-isoCode="country.isoCode"
                    >
                      {{ country.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="country" />
                </div>



                <div class="form-col-half">
                  <label
                    for="state"
                    style="margin-top: 12px;"
                  >State<span>*</span></label>
                  <Field
                    id="state"
                    v-model="state"
                    name="state"
                    as="select"
                  >
                    <option
                      value=""
                      data-isoCode=""
                    >
                      Select State
                    </option>

                    <option
                      v-for="state in filteredStates"
                      :key="state.name"
                      :value="state.name"
                    >
                      {{ state.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="state" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-half">
                  <label
                    for="city"
                    style="margin-top: 12px;"
                  >City<span>*</span></label>
                  <Field
                    v-model="city"
                    type="text"
                    name="city"
                    placeholder="City"
                  />
                  <ErrorMessage name="city" />
                </div>

                <div class="form-col-half">
                  <label
                    for="postalCode"
                    style="margin-top: 12px;"
                  >Postal Code<span>*</span></label>
                  <Field
                    v-model="postalCode"
                    type="text"
                    name="postalCode"
                    placeholder="Postal Code"
                  />
                  <ErrorMessage name="postalCode" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <label for="mapLink">Map Link </label>
                  <Field
                    v-model="mapLink"
                    type="text"
                    name="mapLink"
                    placeholder="Map Link"
                  />
                  <ErrorMessage name="mapLink" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <label for="description">Description<span>*</span> </label>
                  <Field
                    v-model="description"
                    name="description"
                    cols="30"
                    rows="3"
                    as="textarea"
                    placeholder="Description"
                  />
                  <ErrorMessage name="description" />
                </div>
              </div>




              <div class="form-row">
                <div class="form-col-full">
                  <div class="form-actions">
                    <button
                      v-if="mode=='add'"
                      type="button"
                      @click="resetForm"
                    >
                      Reset
                    </button>
                    <button type="submit">
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>

<script setup>
import {ref, inject,provide, watch, onMounted, computed} from 'vue'
import 'flatpickr/dist/flatpickr.css';
import { Form, Field, ErrorMessage} from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import countries from '../../countries.json';
import states from '../../states.json';
import { useStore } from 'vuex';

const emit = defineEmits(['submit'])



const store = useStore(); 

let showSpeakerDialog =  ref(false);

let openVenueDialog = inject('openVenueDialog');
let venueId = inject('venueId');
let venueEditMode = ref(false);
// const formSubmitted = reactive({status:false});
// provide('formSubmitted',formSubmitted);

console.log("Inject",openVenueDialog);

let file = ref(null);

const form  = ref();
const filteredStates = ref([]);

const venueName = ref('');
const addressOne = ref('');
const addressTwo = ref('');
const country = ref('yy');
const state =ref('');
const city =ref('');
const postalCode =ref('');
const mapLink = ref('');
const description = ref('');

const countryElem = ref(null);

// console.log("")




const snackbar= ref(false);
const text= ref('');
const timeout = ref(2000);



watch(openVenueDialog, (newValue) => {
	console.log("newValue",newValue.status);
	showSpeakerDialog.value = newValue.status
});


onMounted(() => {
	// console.log("form",country.value);

});
  




venueEditMode = computed(() => {
	return store.state.venue.venueEditMode;
})

watch(venueEditMode, (newValue) => {
	console.log("venueEditMode",newValue);
	if(newValue === true){
		editVenueForm(venueId.value)
	}
	
});



let editVenueForm = (id)=>{
   
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/venue/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log("edit response",response);
			if(response.status === 200){
        
				let getVenueDetails = response.data.data[0];
				venueName.value = `${getVenueDetails.name?getVenueDetails.name :''}`;
				addressOne.value =  `${getVenueDetails.address1?getVenueDetails.address1 :''}`; 
				addressTwo.value =  `${getVenueDetails.address2?getVenueDetails.address2 :''}`; 
				country.value =  `${getVenueDetails.country?getVenueDetails.country :''}`; 
				state.value =  `${getVenueDetails.state?getVenueDetails.state :''}`; 
				city.value =  `${getVenueDetails.city?getVenueDetails.city :''}`; 
				postalCode.value =  `${getVenueDetails.postalCode?getVenueDetails.postalCode :''}`;
				mapLink.value = `${getVenueDetails.mapLink?getVenueDetails.mapLink :''}`; 
				description.value =  `${getVenueDetails.description?getVenueDetails.description :''}`; 
			
				console.log("form",country.value);
         
				let getIsoCode =  countries.find(element => element.name == country.value).isoCode;
				getIsoCode?getState(getIsoCode):'';
				// console.log('getIsoCode',getIsoCode)
			}
		})
    
  
	
    
}



const selectCountry = (event)=>{
	getState(event.target.options[event.target.selectedIndex].getAttribute('data-isoCode'))
}


const getState = (isoCode)=>{
    
	// console.log("stateskk",states);
	filteredStates.value = states.filter(function (element) {
		return element.countryCode == isoCode;
	});
	console.log("filteredStates",filteredStates);
    
}

const closeDialog = ()=>{

	openVenueDialog.status =false;
	store.state.venue.venueEditMode = false;
	venueName.value ="";
	addressOne.value ="";
	addressTwo.value ="";
	country.value ="";
	state.value ="";
	city.value ="";
	postalCode.value ="";
	mapLink.value ="";
	description.value ="";
	
}





const schema = yup.object({
	venueName:yup.string().required('Please enter venue name'),
	addressOne: yup.string().required('Please enter address'),
	addressTwo:yup.string().required('Please enter address'),
	country: yup.string().required('Please select country'),
	state: yup.string().required('Please select state'),
	city: yup.string().required('Please enter city'),
	postalCode: yup.string().required('Please enter postal code'),
	description: yup.string().required('Please enter description'),
});



const onSubmit = (data) => {

	console.log("data",data);

	if(venueEditMode === true){
		axios
			.put(`${process.env.VUE_APP_SERVICE_URL}/venue/${venueId.value}`,
				{
					"name": data.venueName,
					"address1": data.addressOne,
					"address2": data.addressTwo,
					"country": data.country,
					"state": data.state,
					"city": data.city,
					"postalCode": data.postalCode,
					"mapLink": data.mapLink,
					"description": data.description,
					"hallIds": []
				},
				{headers:{
					'Authorization': `Bearer ${store.state.authentication.token}`,
				}})
			.then((response) => {
				if(response.status === 200){
          
					snackbar.value=true;
					text.value = "Venue updated successfully"; 
					form.value.resetForm();
					closeDialog();
          store.state.venue.tableRefresh = true;
				}
			})
	}else{
		axios
			.post(`${process.env.VUE_APP_SERVICE_URL}/venue`,
				{
					"name": data.venueName,
					"address1": data.addressOne,
					"address2": data.addressTwo,
					"country": data.country,
					"state": data.state,
					"city": data.city,
					"postalCode": data.postalCode,
					"mapLink": data.mapLink,
					"description": data.description,
					"hallIds": []
				},
				{headers:{
					'Authorization': `Bearer ${store.state.authentication.token}`,
				}})
			.then((response) => {
				if(response.status === 200){
          
					snackbar.value=true;
					text.value = "Venue added successfully"; 
					form.value.resetForm();
					closeDialog();
          store.state.venue.tableRefresh = true;
				}
			})
	}


	
}
	



const resetForm = ()=>{
	form.value.resetForm();
	// emit('submit','success');
	// tableRefresh.status=true;
}

</script>

<style scoped>
.v-container{padding: 0;}
.pointer{cursor: pointer;}
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */
.profile-upload{margin-bottom: 22px;}
.profile_image img{display: block;
    margin: 0 auto 15px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;}
.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='file'] + span, input[type='text'] + span ,select + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
.title{flex-basis: 30%;margin-right: 18px;}
.firstName{flex-basis: 70%;}

.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select,.title select,.firstName input{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}

/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
/* .input-group input{border:unset;outline: unset;} */
/* .flat-pickr{width:100%!important;}  */
/* .flatpickr-input{width: 30%;background-color: #007CBA;} */
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}

select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
</style>
