<template>
  <div style="position: fixed;margin:auto;display: block;width: 100%;">
    <AdminPanel>
      <v-container>
        <div class="card-container">
          <div
            class="card"
            :class="{active:selectedInfoTab ==='activeMembers'}"
            @click="selectInfoCard('activeMembers')"
          >
            <div class="card-group">
              <fa icon="person-running" />
              <div class="count">
                {{ activeMember }}
              </div>
            </div>
            <div class="card-name">
              Active Members
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='approvalPending'}"
            @click="selectInfoCard('approvalPending')"
          >
            <div class="card-group">
              <img
                src="../assets/images/approval-pending.svg"
                alt="approval-pending"
              >
              <div class="count">
                {{ approvalPendingMember }}
              </div>
            </div>
            <div class="card-name">
              Approval Pending
            </div>
          </div>
 
          <div
            class="card"
            :class="{active:selectedInfoTab ==='registrationPending'}"
            @click="selectInfoCard('registrationPending')"
          >
            <div class="card-group">
              <img
                src="../assets/images/registration-pending.png"
                alt="registration-pending"
              >
              <div class="count">
                {{ registrationPendingMember }}
              </div>
            </div>
            <div class="card-name">
              Registration Pending 
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='deactivatedMembers'}"
            @click="selectInfoCard('deactivatedMembers')"
          >
            <div class="card-group">
              <fa icon="ban" />
              <div class="count">
                {{ inactiveMember }}
              </div>
            </div>
            <div class="card-name">
              Deactivated Members
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='blocked'}"
            @click="selectInfoCard('blocked')"
          >
            <div class="card-group">
              <fa icon="circle-xmark" />
              <div class="count">
                {{ blockedMember }}
              </div>
            </div>
            <div class="card-name">
              Rejected/Blocked
            </div>
          </div>
        </div>

        <div>
          <MembersList
            v-if="selectedInfoTab ==='activeMembers'"
            :type="'activeMembers'"
          />
          <MembersList
            v-if="selectedInfoTab ==='approvalPending'"
            :type="'approvalPending'"
          />
          <MembersList
            v-if="selectedInfoTab ==='registrationPending'"
            :type="'registrationPending'"
          />
          <MembersList
            v-if="selectedInfoTab ==='deactivatedMembers'"
            :type="'deactivatedMembers'"
          />
          <MembersList
            v-if="selectedInfoTab ==='blocked'"
            :type="'blocked'"
          />
        </div>
      </v-container>
    </AdminPanel>
  </div>
</template>


<script setup>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import MembersList from '@/components/members/MembersList.vue'
import axios from 'axios';
import {ref,onMounted,computed} from 'vue'
import {useStore} from 'vuex';


const store = useStore();
const selectedInfoTab = ref('activeMembers');


const activeMember = computed(()=>{
	return store.state.members.activeMembers;
})

const approvalPendingMember = computed(()=>{
	return store.state.members.approvalPending;
})

const blockedMember = computed(()=>{
	return store.state.members.rejectedMembers;
})

const inactiveMember = computed(()=>{
	return store.state.members.deactivatedMembers;
})

const registrationPendingMember = computed(()=>{
	return store.state.members.registrationPending;
})

onMounted(()=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/userCount`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			let membersCounts = response?.data?.data; 
        
			store.state.members.activeMembers = membersCounts?.activeMember;
			store.state.members.approvalPending = membersCounts?.approvalPendingMember; 
			store.state.members.rejectedMembers = membersCounts?.blockedMember;
			store.state.members.deactivatedMembers = membersCounts?.inactiveMember;
			store.state.members.registrationPending =  membersCounts?.registrationPendingMember;

		})
})

const selectInfoCard = (tabName) =>{
	selectedInfoTab.value = tabName;
           
}



</script>

<style scoped>
  .card-container{display: flex;justify-content: space-evenly;margin-top: -5px;}
   .card{border-radius: 6px;
    border: 1px solid #d3d2d2;
    background-color: #f7f6f6;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 13px;
    cursor: pointer;
    
    }
    .card:nth-child(1){margin-left: unset;}
  .card:hover,.card.active{background-color: #007CBA;color:#fff;transition: ease-in-out .8s;}
  .card-group{display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;}
 
.card-name{font-weight: 600;margin-top: 12px;font-size: 14px;text-align: left;}
.card .svg-inline--fa{padding: 8px;border-radius: 50%;font-size: 21px;}
.card:nth-child(1) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(2) .svg-inline--fa{color:#F2881D;background-color: #EFE3D7;}
.card:nth-child(2) img{color: #F2881D;
    background-color: #EFE3D7;
    width: 28px;
    min-width: 38px;
    height: 37px;
    padding: 3px 7px;
    border-radius: 50%;}
.card:nth-child(3) img{color:#F2881D;background-color: #cbcade;width: 39px;padding: 8px;border-radius: 50%;}
.card:nth-child(3) .svg-inline--fa{color: #3632D4;background-color: #cbcade;}
.card:nth-child(4) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.card:nth-child(5) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
</style>


