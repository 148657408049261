<template>
  <AdminPanel>
   
    
<v-container>
 <EventList v-if="selectedView === 'list'"  @view-type-click="selectView" /> 
 <EventCalendar v-if="selectedView === 'calendar'" @view-type-click="selectView"/>
 {{selectedView}}
</v-container>
    
  </AdminPanel>
</template>


<script setup>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import EventList from '@/components/event_management/EventList.vue'
import EventCalendar from '@/components/event_management/EventCalendar.vue'
import {ref,reactive, onMounted, onUnmounted } from 'vue'



// var handleDateClick = (arg) => {
//       alert('date click! ' + arg.dateStr)
//     }
let selectedView = ref('calendar');

const selectView = (value)=> {
    selectedView.value = value;
    }



  
</script>

<style scoped>
:deep() .fc-event{border-radius: 12px!important;padding-left: 12px;}
:deep() .fc-event-title-container{position: relative;cursor: pointer;}
:deep() .fc-event-title-container::after{position: absolute;content: '\22EE';width: 12px;height:12px;
right: 0;top:1px;}

.event-info {position: relative!important;border-radius: 12px!important;}
.event-info .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.event-info .v-card-text h3{display: flex;align-items: center;}
.event-heading{display: flex;align-items: center; color: black;font-weight: 800;margin-bottom: 12px;}
.event-color{width: 19px;height: 15px;background-color: orange;margin-right: 12px;border-radius: 4px;}
.event-list{display: flex;margin-bottom: 12px;}
.event-icon{width: 28px;}

:deep() .fc-theme-standard th{height:62px;
    background: #83B6CF;
    color: #fff;
    font-weight: 400;}
 :deep() th .fc-scrollgrid-sync-inner{margin-top: 15px;}
 :deep() .fc-scrollgrid-sync-table{background-color: #F6F9F9;}
 :deep() .fc-prev-button,:deep() .fc-next-button{background: #E7E7E7;
    border: 1px solid #E7E7E7;}
   :deep() .fc-icon{margin-top: -10px;}
   :deep() .fc-myCustomButton-button, :deep() .fc-myCustomButton-button:hover{background: #007CBA;
    border: 1px solid;padding: 8px 14px;border-radius: 5px;}
   
</style>

