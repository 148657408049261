<template>
  <AdminPanel>
    <v-container class="container">
      <h2>Create Broadcast</h2>
      <p>Here you can create Broadcast</p>
      <v-card class="create-broadcast-card">
        <Form
          ref="form"
          :validation-schema="schema"
          @submit="onSubmit"
        >
          <v-row>
            <v-col cols="12">
              <label for="title">Title<span>*</span></label>
              <Field
                name="title"
                type="text"
                placeholder="Title"
                class="input-field"
              />
              <ErrorMessage
                name="title"
                class="error-message"
              />
            </v-col>
            <v-col cols="12">
              <label for="message">Message<span>*</span></label>
              <Field name="message">
                <v-textarea
                  v-model="message"
                  placeholder="Message"
                  auto-grow
                  variant="outlined"
                  rows="3"
                  class="input-field-msg"
                />
              </Field>
              <ErrorMessage
                name="message"
                class="error-message"
              />
            </v-col>
            <v-col cols="12">
              <label>Select the Recipient group?<span>*</span></label>
              <v-row class="radio-group">
                <v-col cols="6">
                  <v-radio-group
                    v-model="recipientGroup"
                    row
                  >
                    <v-radio
                      label="Event Based"
                      value="event"
                      class="radio-item"
                    />
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-radio-group
                    v-model="recipientGroup"
                    row
                  >
                    <v-radio
                      label="Virtual Group"
                      value="virtual"
                      class="radio-item"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="recipientGroup === 'event'"
              cols="12"
              sm="6"
            >
              <label for="eventRecipients">Event Recipients</label>
              <v-combobox
                v-model="eventRecipients"
                :items="eventRecipientsOptions"
                placeholder="Event Recipients"
                variant="outlined"
                clearable
                class="dropdown"
              />
              <ErrorMessage
                name="eventRecipients"
                class="error-message"
              />
            </v-col>
            <v-col
              v-if="recipientGroup === 'event'"
              cols="12"
              sm="6"
            >
              <label for="forWhom">For Whom?</label>
              <v-combobox
                v-model="forWhom"
                :items="forWhomOptions"
                variant="outlined"
                clearable
                placeholder="For Whom"
                class="dropdown"
              />
              <ErrorMessage
                name="forWhom"
                class="error-message"
              />
            </v-col>
            <v-col
              v-if="recipientGroup === 'event'"
              cols="12"
              sm="6"
            >
              <label for="industry">Industry</label>
              <v-combobox
                v-model="industry"
                chips
                variant="outlined"
                :items="industryOptions"
                multiple
                clearable
                placeholder="Industry"
                class="dropdown"
              />
              <ErrorMessage
                name="industry"
                class="error-message"
              />
            </v-col>
            <v-col
              v-if="recipientGroup === 'event'"
              cols="12"
              sm="6"
            >
              <label for="chapter">Chapter</label>
              <v-combobox
                v-model="chapter"
                chips
                :items="chapterOptions"
                multiple
                clearable
                variant="outlined"
                placeholder="Chapter"
                class="dropdown"
              />
              <ErrorMessage
                name="chapter"
                class="error-message"
              />
            </v-col>
            <v-col
              v-if="recipientGroup === 'virtual'"
              cols="12"
            >
              <label for="searchUsers">Search Users</label>
              <v-combobox
                v-model="searchUsers"
                chips
                :items="userOptions"
                variant="outlined"
                clearable
                multiple
                placeholder="Search Users"
                class="dropdown-full-width"
              />
              <ErrorMessage
                name="searchUsers"
                class="error-message"
              />
            </v-col>
          </v-row>
          <div class="form-actions">
            <v-btn
              type="button"
              @click="resetForm"
            >
              Reset
            </v-btn>
            <v-btn
              type="button"
              @click="openDialog"
            >
              Schedule
            </v-btn>
            <v-btn type="submit">
              Send
            </v-btn>
          </div>
        </Form>
      </v-card>
    </v-container>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      max-height="220px"
      persistent
    >
      <v-card class="dialog-box">
        <v-card-title class="dialog-title">
          Schedule the Broadcast Message
          <v-icon
            class="close-icon"
            @click="closeDialog"
          >
            close
          </v-icon>
        </v-card-title>
        <v-card-text style="overflow-y: hidden;">
          <v-row>
            <v-col cols="6">
              <label
                class="dialog-lable"
                for="scheduleDate"
              >Schedule Date</label>
              <div class="dialog-input">
                <Field
                  v-slot="{ field }"
                  v-model="scheduleDate"
                  name="scheduleDate"
                >
                  <flat-pickr
                    v-model="scheduleDate"
                    v-bind="field"
                    :config="dateConfig"
                    placeholder="Schedule Date"
                  />
                </Field>  
                <button
                  type="button"
                  data-toggle
                  class="dialog-input-btn"
                >
                  <fa icon="calendar" />
                  <span
                    aria-hidden="true"
                    class="sr-only"
                  >Toggle</span>
                </button>
              </div>
            </v-col>
            <v-col cols="6">
              <label
                class="dialog-lable"
                for="scheduleTime"
              >Schedule Time</label>
              <div class="dialog-input">
                <Field
                  v-slot="{ field }"
                  v-model="scheduleTime"
                  name="scheduleTime"
                >
                  <flat-pickr
                    v-model="scheduleTime"
                    v-bind="field"
                    :config="timeConfig"
                    placeholder="Schedule Time"
                  />
                </Field>
                <button
                  type="button"
                  title="Toggle"
                  data-toggle
                  class="dialog-input-btn"
                >
                  <fa icon="clock" />
                  <span
                    aria-hidden="true"
                    class="sr-only"
                  >Toggle</span>
                </button>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-btn
          class="dialog-btn"
          @click="scheduleBroadcast"
        >
          Schedule
        </v-btn>
      </v-card>
    </v-dialog>
  </AdminPanel>
</template>

<script setup>
import { ref } from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import AdminPanel from '@/components/ui/AdminPanel.vue';

const title = ref('');
const message = ref('');
const recipientGroup = ref('event');
const eventRecipients = ref([]);
const industry = ref([]);
const forWhom = ref([]);
const chapter = ref([]);
const searchUsers = ref([]);
const dialog = ref(false);
const scheduleDate = ref(null);
const scheduleTime = ref(null);

const eventRecipientsOptions = ref(['Option 1', 'Option 2', 'Option 3']);
const industryOptions = ref(['Industry 1', 'Industry 2', 'Industry 3']);
const forWhomOptions = ref(['For Group 1', 'For Group 2', 'For Group 3']);
const chapterOptions = ref(['Chapter 1', 'Chapter 2', 'Chapter 3']);
const userOptions = ref(['User 1', 'User 2']);

const dateConfig = ref({
	wrap: true, // set wrap to true only when using 'input-group'
	altInput: true,
	altFormat: "j-M-Y",
	dateFormat: "Y-m-d",
	altInput: true,
	disableMobile: true,    
});

const timeConfig = ref({
	wrap: true, // set wrap to true only when using 'input-group'
	dateFormat: "H:i",
	noCalendar: true,
	enableTime:true  
});

const schema = yup.object().shape({
	title: yup.string().required('Title is required').max(70, 'Title must be at most 70 characters'),
	message: yup.string().required('Message is required').max(1000, 'Message must be at most 1000 characters'),
	eventRecipients: yup.string().when('recipientGroup', {
		is: 'event',
		then: yup.string().required('Event Recipients are required')
	}),
	industry: yup.array().of(yup.string()),
	forWhom: yup.string(),
	chapter: yup.string(),
	searchUsers: yup.string().when('recipientGroup', {
		is: 'virtual',
		then: yup.string().required('Search Users is required')
	})
});

const resetForm = () => {
	title.value = '';
	message.value = '';
	recipientGroup.value = 'event';
	eventRecipients.value = [];
	industry.value = [];
	forWhom.value = [];
	chapter.value = [];
	searchUsers.value = [];
};

const onSubmit = (values) => {
	console.log('Form submitted with values:', values);
	// Handle form submission, e.g., send broadcast
};

const openDialog = () => {
	dialog.value = true;
};

const closeDialog = () => {
	dialog.value = false;
};

const scheduleBroadcast = () => {
	console.log('Broadcast scheduled with date:', scheduleDate.value, 'and time:', scheduleTime.value);
	closeDialog();
};

</script>

<style scoped>
.create-broadcast-card {
  max-width: 1200px;
  margin: auto;
  padding: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.5rem;
  color: #333;
}

p {
  margin-bottom: 0.5rem;
  color: #666;
}

label {
  font-weight: 600;
  display: block;
  margin-bottom: 0.5rem;
  color: #136E9B;
}

label span {
  color: red;
}

.dropdown-full-width {
  width: 100% !important;
}

.input-field {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  margin-bottom: 0.75rem;
  font-size: 1rem;
}

.error-message {
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: -0.5rem;
  margin-bottom: 0.75rem;
}

.radio-group {
  display: flex;
  align-items: center;
  color: #136E9B;
  margin-bottom: -30px;
}

.radio-buttons {
  display: flex;
  gap: rem;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
}

.v-btn {
  padding: 0.5rem 1.5rem;
  margin-top: 2rem;
  gap:15px;
  background-color: #136E9B;
  color: white;
  text-transform: none;
}

.dropdown {
  width: 100%;
  max-width: 450px;
  max-height: 50px;
  box-shadow: #B2B2B2;
  border-radius: 4px;
}

.dialog-title{
  padding: 10px;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 10px;
}

.dialog-lable{
  font-size: 0.85rem;
  margin-top:0;
  color: #136E9B;
  font-weight: 600;
}

.dialog-btn{
  background-color: #136E9B;
  color:white;
  margin-bottom:17px;
  font-size: 0.75rem;
  width: 150px;
  margin-left: 330px;
  text-transform: none;
}

.dialog-input{
  width:200px;
  height: 35px;
  padding-left: 0.5rem; 
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.close-icon{
  margin-left: 186px;
  margin-top: -30px;
  color:#B2B2B2
}

.dialog-input button{
  margin-left: -35px; 
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dialog-input .fa-calendar, .dialog-input .fa-clock{
  color:#B2B2B2;
}
</style>
