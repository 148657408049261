<template>
  <AdminPanel>
    <v-container>
      <div class="card-container">
        <div
          class="card"
          :class="{active:selectedInfoTab ==='ActiveMembers'}"
          @click="selectInfoCard('ActiveMembers')"
        >
          <div class="card-group">
            <fa icon="person-running" />
            <div class="count">
              {{ activeMember }}
            </div>
          </div>
          <div class="card-name">
            Active Members
          </div>
        </div>

        <div
          class="card"
          :class="{active:selectedInfoTab ==='ApprovalPending'}"
          @click="selectInfoCard('ApprovalPending')"
        >
          <div class="card-group">
            <img
              src="../assets/images/approval-pending.svg"
              alt="approval-pending"
            >
            <div class="count">
              {{ approvalPendingMember }}
            </div>
          </div>
          <div class="card-name">
            Approval Pending
          </div>
        </div>
 
        <div
          class="card"
          :class="{active:selectedInfoTab ==='RegistrationPending'}"
          @click="selectInfoCard('RegistrationPending')"
        >
          <div class="card-group">
            <img
              src="../assets/images/registration-pending.png"
              alt="registration-pending"
            >
            <div class="count">
              {{ registrationPendingMember }}
            </div>
          </div>
          <div class="card-name">
            Registration Pending
          </div>
        </div>

        <div
          class="card"
          :class="{active:selectedInfoTab ==='DeactivatedMembers'}"
          @click="selectInfoCard('DeactivatedMembers')"
        >
          <div class="card-group">
            <fa icon="ban" />
            <div class="count">
              {{ inactiveMember }}
            </div>
          </div>
          <div class="card-name">
            Deactivated Members
          </div>
        </div>

        <div
          class="card"
          :class="{active:selectedInfoTab ==='RejectedOrBlocked'}"
          @click="selectInfoCard('RejectedOrBlocked')"
        >
          <div class="card-group">
            <fa icon="circle-xmark" />
            <div class="count">
              {{ blockedMember }}
            </div>
          </div>
          <div class="card-name">
            Rejected/Blocked
          </div>
        </div>
      </div>

      <div>
        <ActiveMembers v-if="selectedInfoTab ==='ActiveMembers'" />
        <ApprovalPending v-if="selectedInfoTab ==='ApprovalPending'" /> 
        <RegistrationPending v-if="selectedInfoTab ==='RegistrationPending'" /> 
        <DeactivatedMembers v-if="selectedInfoTab ==='DeactivatedMembers'" /> 
        <RejectedOrBlocked v-if="selectedInfoTab ==='RejectedOrBlocked'" /> 
      </div>
    </v-container>
  </AdminPanel>
</template>


<script>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import ActiveMembers from '@/components/members/ActiveMembers.vue'
import ApprovalPending from '@/components/members/ApprovalPending.vue'
import RegistrationPending from '@/components/members/RegistrationPending.vue'
import DeactivatedMembers from '@/components/members/DeactivatedMembers.vue'
import RejectedOrBlocked from '@/components/members/RejectedOrBlocked.vue'
import axios from 'axios';

export default {
	components: {
		AdminPanel,
		ActiveMembers,
		ApprovalPending,
		RegistrationPending,
		DeactivatedMembers,
		RejectedOrBlocked

	},
	data(){
		return {
			selectedInfoTab:'ActiveMembers',
		}},
	computed: {
		activeMember(){
			return this.$store.state.members.activeMembers
		},
		approvalPendingMember(){
			return this.$store.state.members.approvalPending
		},
		blockedMember(){
			return this.$store.state.members.rejectedMembers
		},
		inactiveMember(){
			return this.$store.state.members.deactivatedMembers
		},
		registrationPendingMember(){
			return this.$store.state.members.registrationPending
		}
	},
	mounted(){
		axios
			.get(`${process.env.VUE_APP_SERVICE_URL}/userCount`,{
				headers: {
					'Authorization': `Bearer ${this.$store.state.authentication.token}`,
				}})
			.then((response) => {
				let membersCounts = response?.data?.data; 
        
				this.$store.state.members.activeMembers = membersCounts?.activeMember;
				this.$store.state.members.approvalPending = membersCounts?.approvalPendingMember; 
				this.$store.state.members.rejectedMembers = membersCounts?.blockedMember;
				this.$store.state.members.deactivatedMembers = membersCounts?.inactiveMember;
				this.$store.state.members.registrationPending =  membersCounts?.registrationPendingMember;

			})
	},
	methods:{
		selectInfoCard(tabName){
			this.selectedInfoTab = tabName;
           
		}
	}
}

</script>

<style scoped>
  .card-container{display: flex;justify-content: space-evenly;margin-top: 22px;}
   .card{border-radius: 6px;
    border: 1px solid #d3d2d2;
    background-color: #f7f6f6;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 13px;
    cursor: pointer;
    
    }
    .card:nth-child(1){margin-left: unset;}
  .card:hover,.card.active{background-color: #007CBA;color:#fff;transition: ease-in-out .8s;}
  .card-group{display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;}
 
.card-name{font-weight: 600;margin-top: 12px;font-size: 14px;text-align: left;}
.card .svg-inline--fa{padding: 8px;border-radius: 50%;font-size: 21px;}
.card:nth-child(1) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(2) .svg-inline--fa{color:#F2881D;background-color: #EFE3D7;}
.card:nth-child(2) img{color: #F2881D;
    background-color: #EFE3D7;
    width: 28px;
    min-width: 38px;
    height: 37px;
    padding: 3px 7px;
    border-radius: 50%;}
.card:nth-child(3) img{color:#F2881D;background-color: #cbcade;width: 39px;padding: 8px;border-radius: 50%;}
.card:nth-child(3) .svg-inline--fa{color: #3632D4;background-color: #cbcade;}
.card:nth-child(4) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.card:nth-child(5) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
</style>


