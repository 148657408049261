<template>
  <div class="tab-heading-and-action">
    <h3>Rejected / Blocked </h3>
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
      </div>
    
      <fa
        icon="filter"
        class="filter-btn"
        @click="openFilter"
      />
       
      <div
        v-if="showFilter === true"
        class="filtercard"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="closeFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div
            class="reset-btn pointer"
            @click="resetFilter"
          >
            RESET
          </div>
        </div>
       
      
        <select
          id="BusinessCategory"
          v-model="selectedBusinessCategory"
          name="BusinessCategory"
          @change="selectBusinessCategory"
        >
          <option value="">
            Business Category
          </option>
          <option value="Consumer Services">
            Consumer Services
          </option>
          <option value="Health Care">
            Health Care
          </option>
        </select>

        <select
          id="gender"
          v-model="selectedGender"
          name="gender"
        >
          <option value="">
            Gender
          </option>
          <option value="Male">
            Male
          </option>
          <option value="FeMale">
            FeMale
          </option>
        </select>

        <div class="input-group">
          <flat-pickr
            v-model="blockedOn"
            :config="dateConfig"
            class="date-form-control"
            placeholder="Rejected/Blocked On"
            name="blockedOn"
          />
          <div class="input-group-append">
            <button
              type="button"
              colorpicker="colorpickere"
              data-toggle
            >
              <fa icon="calendar" />
              <span
                aria-hidden="true"
                class="sr-only"
              >Toggle</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.action`]="{ item }">
      <fa
        icon="eye"
        class="pointer"
        @click="viewMember(item.action)"
      />
      <fa
        icon="trash"
        class="pointer"
        @click="deleteMemberConfirmation(item.action)"
      />
      <fa
        icon="circle-check"
        class="pointer"
        @click="approveMemberConfirmation(item.action)"
      />
    </template>
  </v-data-table-server>

  
  <div class="text-center">
    <!-- delete dialog -->
    <v-dialog 
      v-model="deleteDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="deleteDialog = false"
        >
          <fa icon="close" />
        </div>
        
          
        <v-card-text>
          <h3> <fa icon="trash-can" /> Are you sure?</h3>
          Are you sure you want to delete this Rejected this member? This action 
          cannot be undone.
        </v-card-text>
     
        <v-card-actions class="delete-action">
          <button @click="deleteDialog = false">
            Cancel
          </button>
          <button @click="deleteMember">
            Delete
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->

   
    <!-- approve dialog -->
    <v-dialog 
      v-model="approveDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="approveDialog = false"
        >
          <fa icon="close" />
        </div>
        
          
        <v-card-text>
          <h3> <fa icon="trash-can" /> Are you sure?</h3>
          Are you sure you want to approve this member? This action 
          cannot be undone.
        </v-card-text>
     
        <v-card-actions class="delete-action">
          <button @click="approveDialog = false">
            No
          </button>
          <button
            style="background-color:#007CBA;border:1px solid #007CBA;"
            @click="approveMember"
          >
            Yes, Sure
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- approve dialog -->

    
      
    <div
      v-if="viewDialog"
      class="view-dialog-overlay"
    >
      <div class="view-member-modal">
        <div class="view-modal-action">
          <fa
            icon="arrow-right-long"
            @click="viewDialog = false"
          />
          <h3>Rejected/Blocked</h3>
        </div>

        <div class="view-member-details">
          <div class="member-details-content">
            <h5>Full Name</h5>
            <p>{{ memberView.fullName }}</p>
            <h5>Gender</h5>
            <p>{{ memberView.gender }}</p>
            <h5>Date of Birth</h5>
            <p> {{ memberView.dob }}</p>
            <h5>Phone Number</h5>
            <p>{{ memberView.phone }}</p>
            <h5>Email</h5>
            <p>{{ memberView.email }}</p>
            <h5>Company Name</h5>
            <p>{{ memberView.companyName }}</p>
            <h5>Designation</h5>
            <p>{{ memberView.designation }}</p>
            <h5>City</h5>
            <p>{{ memberView.city }}</p>
            <h5>Business Category</h5>
            <p>I{{ memberView.businessCategory }}</p>
            <h5>Business Subcategory</h5>
            <p>{{ memberView.businessSubcategory }}</p>
            <h5>Website</h5>
            <p>{{ memberView.website }}</p>
            <h5>Chapter</h5>
            <p>{{ memberView.chapter }}</p>
            <h5>Member Since</h5>
            <p> {{ memberView.memberSince }}</p>
            <h5>Rejected/Blocked On</h5>
            <p> {{ memberView.blockedOn }}</p>

            
            <button
              class="approve"
              @click="approveMemberConfirmation(memberId)"
            >
              Approve
            </button>
            <button @click="deleteMemberConfirmation(memberId)">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>





<script>
import axios from 'axios';
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
	name:'DeactivatedMembers',
	components:{
		flatPickr
	},
	data: () => ({
		itemsPerPage: 5,
		offset:0,
		headers: [
			{ title: 'First Name', value: 'first_name' },
			{ title: 'Middle Name', value: 'middle_name' },
			{ title: 'Last Name', value: 'last_name' },
			{ title: 'Phone Number', value: 'phone_number' },
			{ title: 'Business Category', value: 'business_category' },
			{ title: 'Chapter', value: 'chapter' },
			{ title: 'Member Since', value: 'member_since' },
			{ title: 'Rejected/Blocked On', value:'blocked_on' },
			{ title: 'Action', value: 'action' }
		],
		serverItems: [],
		loading: true,
		totalItems: 0,
		memberId:'',
		memberView:{
			fullName:'',
			gender:'',
			dob:'',
			phone:'',
			email:'',
			companyName:'',
			designation:'',
			city:'',
			businessCategory:'',
			businessSubcategory:'',
			website:'',
			chapter:'',
			memberSince:'',
			blockedOn:'',
			lastActivity:''
		},
		dateConfig:{
			wrap: true, // set wrap to true only when using 'input-group'
			altInput: true,
			altFormat: "j-M-Y",
			dateFormat: "Y-m-d",
			disableMobile: true,    
		},
		blockedOn:'',
		showFilter:false,
		query:'',
		selectedBusinessCategory:'',
		selectedGender:'',
		name: '',
		calories: '',
		search: '',
		deleteDialog: false,
		approveDialog:false,
		viewDialog:false,
		snackbar:false,
		text: '',
		timeout: 2000,
		testId:'',
	}),
	watch: {
		name () {
			this.search = String(Date.now())
		},
		selectedBusinessCategory () {
			this.search = String(Date.now())
		},
		selectedGender () {
			this.search = String(Date.now())
		},
		blockedOn(value) {
			console.log('value',value);
			this.search = String(Date.now())
		}
	},
	methods: {
		loadItems ({ page, itemsPerPage, sortBy }) {
			this.offset = (page - 1) * itemsPerPage
			console.log("offset",this.offset);
			this.loading = true
        
     
			axios
				.get(`${process.env.VUE_APP_SERVICE_URL}/users?memberStatus=blocked&limit=${this.itemsPerPage}&offset=${this.offset}`,{
					params:{
						value:this.name,
						businessCategory: this.selectedBusinessCategory,
						gender: this.selectedGender,
						// test:this.blockedDate
						blockedDate:this.blockedOn
					}, 
					headers: {
						'Authorization': `Bearer ${this.$store.state.authentication.token}`,
					}})
				.then((response) => {
					this.serverItems = []
					console.log('response.data.title',response.data);
					// this.serverItems = response.data.data;
					this.totalItems = response.data.totalCount;
					this.loading = false
          
					let memberData = response.data.data;
					memberData.forEach((item,index) => {
            
						// 	this.serverItems.push({
						//     first_name: item.name.first?item.name.first:'-',
						// 		middle_name: item.name.middle?item.name.middle:'-',
						// 		last_name: item.name.last?item.name.last:'-',
						// 		phone_number: `${item.phone.dialCode?item.phone.dialCode:''} ${item.phone.number?item.phone.number:'N/A'}`,
						// 		business_category: item.industry?item.industry:'N/A',
						// 		chapter: item.state?item.state:'N/A',
						// 		member_since:this.formattedDate(item.createdAt),
						// 		blocked_on:this.formattedDate(item.updatedAt),
						// 		last_activity:this.relatedTime(item.updatedAt) + ' (U)',
						// 		action:item._id})
						// });

						let memberList = {
							'first_name': item?.firstName || '',
							'middle_name': item?.middleName || '',
							'last_name': item?.lastName || '',
							'phone_number':`${item?.phone?.countryCode ?? ''} ${item?.phone?.number ?? 'N/A'}`,
							'business_category': item?.industry || '',
							'chapter': item?.state || '',
							'member_since':item?.createdAt?this.formattedDate(item?.createdAt) : '',
							'blocked_on': item?.updatedAt?this.formattedDate(item.updatedAt):'',
							// 'last_activity':item?.loginDate?this.memberActivity(item?.loginDate?item?.loginDate:'',item?.updatedAt):'',
							'action':item?._id,
			        'view':item}

						if(item?.loginDate){
							memberList.last_activity = this.memberActivity(item.loginDate,item?.updatedAt)
						}else{
							memberList.last_activity ='';
						}

						this.serverItems.push(memberList)
					});
          
					this.$store.state.members.rejectedMembers = response?.data?.totalCount;

				})
				.catch((error)=>{
					if (error.response) {
						if(error.response.data.message){

							if(error.response.data.message ==='jwt expired'){
								this.$store.commit('snackbar/showSnackbar',{
									show:true,
									message:'Token expired',
									color:'danger',
									timeout:2000});
								 this.$store.commit('authentication/logout',2000);
							}
							

						}else{
							this.$store.commit('snackbar/showSnackbar',{
								show:true,
								message:error.response.statusText,
								color:'danger',
								timeout:2000});
						}
					}else {
						this.$store.commit('snackbar/showSnackbar',{
							show:true,
							message:error.message,
							color:'danger',
							timeout:2000});
					}
				});

        
		},
		viewMember(id){
			this.memberId = id;
			axios
				.get(`${process.env.VUE_APP_SERVICE_URL}/users/${id}`,{
					headers: {
						'Authorization': `Bearer ${this.$store.state.authentication.token}`,
					}})
				.then((response) => {
					this.viewDialog=true;
					console.log("response",response);
					let getMemberDetails = response.data.data;
					this.memberView.fullName = getMemberDetails?.name?.first+ " " +getMemberDetails?.name?.last;
					this.memberView.gender = getMemberDetails?.gender || 'N/A';
					this.memberView.dob = getMemberDetails?getMemberDetails.dateOfBirth?this.formattedDate(getMemberDetails.dateOfBirth):'N/A':'';
					this.memberView.phone = `${getMemberDetails?.phone?.dialCode || ''} ${getMemberDetails?.phone?.number || 'N/A'}`;
					this.memberView.email = getMemberDetails?.email || 'N/A';
					this.memberView.companyName = getMemberDetails?.companyName || 'N/A';
					this.memberView.designation = getMemberDetails?.designation || 'N/A';
					this.memberView.city = getMemberDetails?.city || 'N/A';
					this.memberView.businessCategory = getMemberDetails?.industry || 'N/A';
					this.memberView.businessSubcategory = getMemberDetails?.industrySubCategory || 'N/A';
					this.memberView.website = getMemberDetails?.website || 'N/A';
					this.memberView.chapter = getMemberDetails?.state || 'N/A';
					this.memberView.memberSince = getMemberDetails?getMemberDetails.createdAt?this.formattedDate(getMemberDetails.createdAt):'N/A':'';
					this.memberView.blockedOn = getMemberDetails?getMemberDetails.updatedAt?this.formattedDate(getMemberDetails.updatedAt):'N/A':'';
					this.memberView.lastActivity =  getMemberDetails?getMemberDetails.updatedAt?this.relatedTime(getMemberDetails.updatedAt)+ ' (U)':'N/A':'';



       

				})
		},
		deleteMemberConfirmation(item){
			this.memberId = item;
			this.deleteDialog=true;
		},
		deleteMember(){
			console.log(this.memberId);
			axios
				.delete(`${process.env.VUE_APP_SERVICE_URL}/users/${this.memberId}`,
					{headers:{
						'Authorization': `Bearer ${this.$store.state.authentication.token}`,
					}})
				.then((response) => {
					console.log(" deactivate response",response);
					if(response.status === 200){
						this.deleteDialog=false;
						this.viewDialog=false;
						this.search = String(Date.now());
						this.snackbar=true;
						this.text = "Deleted Successfully"; 
						
					}
				})
		},
		approveMemberConfirmation(item){
			this.memberId = item;
			this.approveDialog=true;
		},
		approveMember(){
			console.log(this.memberId);
			axios
				.put(`${process.env.VUE_APP_SERVICE_URL}/users/${this.memberId}`,
					{'isActive':true},
					{headers:{
						'Authorization': `Bearer ${this.$store.state.authentication.token}`,
					}})
				.then((response) => {
					if(response.status === 200){
						this.approveDialog=false;
						this.viewDialog=false;
						this.search = String(Date.now());
						this.snackbar=true;
						this.text = "Approved Successfully"; 
						
					}
				})
		},
		blockMember(){
			console.log(this.memberId);
			axios
				.put(`${process.env.VUE_APP_SERVICE_URL}/users/${this.memberId}`,
					{'memberStatus':'blocked'},
					{headers:{
						'Authorization': `Bearer ${this.$store.state.authentication.token}`,
					}})
				.then((response) => {
					console.log(" deactivate response",response);
					if(response.status === 200){
						this.deleteDialog=false;
						this.viewDialog=false;
						this.snackbar=true;
						this.text = "Blocked Successfully"; 
						this.loading = true;
						setTimeout(() => {
							this.loading = false
						}, 2000)
					}
				})
		},
		openFilter(){
			this.showFilter = true;
		},
		closeFilter(){
			this.showFilter = false;
		},
		resetFilter(){
			this.selectedBusinessCategory='';
			this.selectedGender='';
		},
		formattedDate(d){
			return moment(d).format('DD-MMM-YYYY');
		},
		relatedTime(data){
			let localTime  = moment.utc(data).toDate();
			localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
			let difference = moment(localTime).fromNow(); 
			return difference;
		} 
	}
    
}


</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 18px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.search-container{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .search-container .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;}  
 .search-container input{outline: unset;}

 .filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
.filter-btn{position: relative;cursor: pointer;}
.filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
.filtercard select{border: 1px solid #B2B2B2;
    padding: 6px;
    margin-bottom: 12px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:#B2B2B2;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
.filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
.reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;}
.filter-close-btn{width: max-content;
    margin-left: auto;
    display: block;
   cursor: pointer;}

 /* datepicker    */
 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;padding: 7px;}
  .input-group-append {
  display: flex;
  align-items: center; 
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
 /* datepicker end */  

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th{width: 120px;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-trash{color:#FF0000;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-circle-check{color: #8BC34A;}

.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}

 .view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
</style>



