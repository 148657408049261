<template>
  <div style="position: fixed;margin:auto;display: block;width: 100%;">
    <AdminPanel>
      <div
        ref="main"
        class="main"
      >
        <div class="topic-section">
          <div class="topic-action">
            <h3>Topics</h3>
            <div
              class="add-event"
              @click="addTopic"
            >
              <fa icon="plus" /> Add New 
            </div>
          </div>
        

          <div
            class="topic-list"
          >
            <div
              v-for="(item,index) in queueList"
              :key="index"
              class="topic-card"
              :data-id="item.id"
              :data-duration="item.duration"
              :data-title="item.title"
              :class="'external-event'" 
              :draggable="true"
              @dragstart="onEventDragStart($event)"
              @dragover="onEventDragOver($event)"
              @drag="onDrag($event)"
            >
              <div
                class="topic-title"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>


     

        <div class="scheduler">
          <div
            class="scheduler-header"
          >
            <h1>Schedule</h1>
            <div class="scheduler-navigation">
              <button
                id="prevBtn"
                @click="prev"
              >
                <fa icon="chevron-left" />
              </button>
              <span>{{ formattedDateWithMonth(displayDate.date) }}</span>
              <button
                id="nextBtn"
                @click="next"
              >
                <fa icon="chevron-right" />
              </button>
            </div>
          </div>
         
          <div
            class="scheduler-height" 
            :style="schedulerHeight"
          >
            <div class="scheduler-body">
              <div
                class="time-column"
              >
                <div
                  v-for="(item,index) in timeSlot"
                  :key="index"
                  class="time-cell"
                  style="height: 40px;"
                  :data-start="item"
                  :data-end="addMinutes(item, timeStep)"
                >
                  <span
                    class="time-cell-line"
                  />
                  <span class="time-cell-label">{{ item }}</span>
                </div>
              </div>

              <div
                ref="dateSliderContainer"
                class="date-slide-container"
              >
                <div
      
                  v-for="(item,index) in dates"
                  :key="index"
                  ref="dateColumn"
                  class="date-column"
                  :style="'background-color:' +item.color"
                  :data-date="item.date"
                  @dragover="allowDrop($event)"
                  @drop="handleDrop($event)"
                >
                  <div class="date-label">
                    <div v-html="formattedDateWithOrdinal(item.date)" />
                  </div>
                  <div class="track-container">
                    <div
                      v-for="(track,trackIndex) in item.tracks"
                      :key="trackIndex"
                      class="track"
                      :data-track-id="track.id"
                    >
                      <div class="track-label">
                        {{ track.name }}
                      </div>
                      <div
                        class="track-cell"
                  
                        @dragover="allowDrop($event)"
                      >
                        <div
                          v-for="(event,eventIndex) in filteredEvents(item.date,track.id)"
                          :id="'card_'+event.id"
                          :key="event.id"
                          class="topic-card resize-card"
                          :data-id="event.id"
                          :data-duration="event.duration"
                          :data-title="event.title"
                          :draggable="true"
                          :class="'external-event'" 
                          :style="eventStyles(event)"
                          @dragstart="onEventDragStart($event)"
                          @dragover="onEventDragOver($event)"
                          @drag="onDrag($event)"
                        >
                          <div class="topic-title">
                            {{ event.title }} 
                          </div>
                          <div class="time-block">
                            <span class="start-time">{{ formatTime(event.start) }}</span> &nbsp; to &nbsp;
                            <span class="end-time">{{ formatTime(event.end) }} Dur({{ event.duration }})</span>
                          </div>
                          <div
                            class="resize-handle"
                            @mousedown="onResizeMouseDown($event, event)"
                          />
                          <div
                            class="extend-event-front" 
                            :class="allowExtendFrontDrag(item, event, track.id)"
                            :data-extendDate="item.date"
                            :data-extendEventId="event.id"
                            :data-extendTrackId="track.id"
                            :draggable="true"
                            @dragstart="extendEventStart($event)"
                          />
                          <div
                            class="extend-event-back" 
                            :class="allowExtendDrag(item, event, track.id)"
                            :data-extendDate="item.date"
                            :data-extendEventId="event.id"
                            :data-extendTrackId="track.id"
                            :draggable="true"
                            @dragstart="extendEventStart($event)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    class="add-track"
                    @click="addTrack(item.date)"
                  >
                    <span>&#xFF0B;</span> Add Track
                  </button>
                </div>
              </div>
            </div>
          </div>  
        </div>  
      </div>
    </AdminPanel>
  </div>
</template>


<script setup>
// @ is an alias to /src
import moment from 'moment';
import {ref, reactive, provide, onMounted, onBeforeMount, computed, onUpdated, watch } from 'vue';
import { formatTime, formattedDateWithMonth, formattedDateWithOrdinal, parsedDate, isoDateformat, generateRandomId } from '@/helper/index.js';
import AdminPanel from '@/components/ui/AdminPanel.vue'
import { useStore } from 'vuex';
import EventCard from '@/components/event_management/scheduler/EventCard.vue';



const store = useStore();
const dates = ref([{date:'2024-06-27',color:'orange',tracks:[{id:'1',name:'Track 1',color:'orange'},{id:'2',name:'Track 2',color:'green'},{id:'3',name:'Track 3',color:'green'}]},
	{date:'2024-06-28',color:'pink',tracks:[{id:'1',name:'Track 1 rr',color:'orange'}]},
	{date:'2024-06-29',color:'yellow',tracks:[{id:'1',name:'Track 1',color:'orange'}]},
	{date:'2024-06-30',color:'green',tracks:[{id:'1',name:'Track 1',color:'orange'}]}
]);
const schedulerHeight = ref('');
let currentIndex = ref(0);
const displayDate = ref('');
const dateColumn = ref([]);
const timeSlot = ref([])
const timeStep = ref(30);
const timeCellHeight = ref(40);
const timeRange = ref({start:8,end:16})
// const dateColumnWidth = dateColumn[0].clientWidth;

const dateSliderContainer = ref(null)
const main = ref(null)

const currentEvent = ref(null);
const initialY = ref(0);
const initialHeight = ref(0);
const minuteHeight = ref(2);

const queueList = reactive([
	{id: "19771",
		duration: 60,
		title: "Starup 2024"},
	{id: "19772",
		duration: 30,
		title: "LCW 2024",}
])

const events = reactive([
	{
		id: "19801",
		start: "2024-06-27 10:00",
		end: "2024-06-27 10:30",
		duration: 30,
		title: "FX 2024",
		track: ['1']
	},
	{
		id: "19810",
		start: "2024-06-27 14:00",
		end: "2024-06-27 15:00",
		duration: 60,
		title: "AI REV",
		track: ['1']
	},
	{
		id: "14946",
		start: "2024-06-27 12:00",
		end: "2024-06-27 12:45",
		duration: 45,
		title: "Tech expo ",
		track: ['1']
	},
	{
		id: "14956",
		start: "2024-06-30 10:00",
		end: "2024-06-30 11:30",
		duration: 90,
		title: "chennai event",
		track: ['1']
	}

]);




onMounted(()=>{
	setActive(0)
	getTimeSlot(timeRange.value.start+':00',timeRange.value.end+':00',timeStep.value)
	console.log('dateSliderContainer',dateSliderContainer)

	
	// @dragend ="onEventDrop"
	// main.value.addEventListener('drop', handleDrop)
	// dateSliderContainer.value.addEventListener('drop', handleDrop)
})


function updateDateDisplay() {
	displayDate.value = dates.value[currentIndex.value];
	
}


const prev =  () => {
	if (currentIndex.value > 0) {
		currentIndex.value--;
		updateDateDisplay();
		setActive(currentIndex.value)
		moveSlides('prev')
		// updateCarousel(document.querySelectorAll('.date-column')[currentIndex.value].clientWidth)

	// 	let containerDimensions = item.getBoundingClientRect();
		// let containerWidth = containerDimensions.width;
	}
};

const next = () => {
	

	if (currentIndex.value < dates.value.length - 1) {
		currentIndex.value++;
		updateDateDisplay();
		
		setActive(currentIndex.value)
		moveSlides('next')
		// updateCarousel(document.querySelectorAll('.date-column')[currentIndex.value].clientWidth)
			
		
	}
};

const setActive = (currentIndex)=>{
	document.querySelectorAll('.date-column').forEach((item,index) =>{
		if(currentIndex == index){
			item.classList.add('active'); 
		}else{
			item.classList.remove('active');
		}
			
	})
}

const moveSlides = (mode) => {
	let sliderContainerDimension = dateSliderContainer.value.getBoundingClientRect();
	let sliderContainerWidth = sliderContainerDimension.width;
	if(mode=='next'){
		dateSliderContainer.value.scrollLeft += sliderContainerWidth;
	}else{
		dateSliderContainer.value.scrollLeft -= sliderContainerWidth;
	}
};

updateDateDisplay();

const getTimeSlot = (start, end, interval)=>{
// Set start and end times
	let startTime = moment(start, 'HH:mm');
	const endTime = moment(end, 'HH:mm');

	// Loop through time with a step of 15 minutes
	while (startTime <= endTime) {
		timeSlot.value.push(startTime.format('HH:mm'));
		startTime.add(interval, 'minutes');
	}
}

const addTrack = (date) =>{
  
	const dateObj = dates.value.find(item => item.date === date);

	if (dateObj) {
		// Add the new track
		dateObj.tracks.push({
			id:'2',
			name: "Track 2",
			color: "green"
		});
	}
}

const onEventDragStart = (e)=>{
	console.log('e',e)
	let obj = e.target;
	let eventData = {
		id: obj.dataset.id,
		duration: obj.dataset.duration,
		title: obj.dataset.title
	};
	console.log('eventData',eventData)
	        let jsonData = JSON.stringify(eventData);
	        e.dataTransfer.setData("text", jsonData);
 
}

const onEventDragOver = (e)=>{
	e.preventDefault();
}


const allowDrop = (e)=>{
	e.preventDefault();
}
const handleDrop = (e)=>{
	e.preventDefault();
	const dropObj = JSON.parse(e.dataTransfer.getData("text"));
	console.log('dropOb',dropObj)
	const eventId = dropObj.id;
	console.log('eventId',eventId)
	const dropContainer = e.target.closest(".track");
	const droppedDate = e.target.closest('.date-column')?.getAttribute("data-date");
	const trackId = e.target.closest('.track')?.getAttribute("data-track-id");
	const eventDuration = dropObj?.duration || timeStep.value;
	const eventTitle = dropObj.title;
	console.log("droppedDate",droppedDate)
	if(dropObj.extendTrackId && (dropObj.extendEventDate == droppedDate)){
		console.log("EXTENDING",dropObj)
		console.log("start track id",dropObj.extendTrackId)
		console.log("end track id",trackId)
		let existingEvent = events.find(e => e.id == dropObj.extendEventId);
		console.log('existingEvent.track[0]',existingEvent.track[0])
		
		getIdsInRange(dropObj.extendEventId, existingEvent.track[0], trackId, droppedDate)
	}else{
		console.log("NOT EXTENDING",dropObj)
	}
	

	if(eventId){
		
		console.log("trackId",trackId)
		console.log("eventId",eventId)
		console.log("eventDuration",eventDuration)
		console.log("eventTitle",eventTitle)
 
		


		// time update
		const dropY = e.clientY;
		const timeCells = document.querySelectorAll(".time-cell");
		let startTime = null;
		let endTime = null;
		let exactDropTime = null;
		for (const cell of timeCells) {
			const cellRect = cell.getBoundingClientRect();
			if (dropY >= cellRect.top && dropY <= cellRect.bottom) {
				startTime = cell.getAttribute("data-start");
				endTime = cell.getAttribute("data-end");

			 // Calculate exact drop time
			 const startHour = parseInt(startTime.split(':')[0]);
				const startMinute = parseInt(startTime.split(':')[1]);
				const endHour = parseInt(endTime.split(':')[0]);
				const endMinute = parseInt(endTime.split(':')[1]);

				const totalSlotMinutes = (endHour - startHour) * 60 + (endMinute - startMinute);
				const dropPositionWithinCell = (dropY - cellRect.top) / cellRect.height;
				const dropMinutes = totalSlotMinutes * dropPositionWithinCell;

				const exactHour = Math.floor(startHour + (startMinute + dropMinutes) / 60);
				const exactMinute = Math.floor((startMinute + dropMinutes) % 60);

				exactDropTime = `${exactHour.toString().padStart(2, '0')}:${exactMinute.toString().padStart(2, '0')}`;
				console.log("exactDropTime-->",exactDropTime);

				break;
			}
		}

	


		

		// remove data from queue
		const queueIndex = queueList.findIndex(item => item.id === eventId)
		if (queueIndex > -1) queueList.splice(queueIndex, 1)

		// update event data
		let existingEvent = events.find(e => e.id == eventId);
		console.log("START TIME",startTime,`${isoDateformat(droppedDate)} ${startTime}`)
		console.log("END TIME",startTime,`${isoDateformat(droppedDate)} ${endTime}`)
		console.log("END exactDropTime",`${isoDateformat(droppedDate)} ${addMinutes(exactDropTime, eventDuration)}`)
		console.log("DURATION",eventDuration)

		if (existingEvent) {
		// Update the existing event
			existingEvent.start = `${isoDateformat(droppedDate)} ${startTime}`;
			existingEvent.end = `${isoDateformat(droppedDate)} ${addMinutes(exactDropTime, eventDuration)}`;
			existingEvent.title = eventTitle;
			existingEvent.duration = calculateDuration(startTime, addMinutes(exactDropTime, eventDuration));
			console.log("DURATION",eventDuration)
			console.log('duration calculated',calculateDuration(startTime, addMinutes(exactDropTime, eventDuration),existingEvent.title))
			if(existingEvent.track.length == 1){
				existingEvent.track = [trackId];
			}
			
			console.log('existingEvent',existingEvent)
		
		
		}else{
			events.push({
				id:eventId,
				start:isoDateformat(droppedDate)+' '+startTime,
				end:isoDateformat(droppedDate)+' '+addMinutes(exactDropTime, eventDuration),
				duration:calculateDuration(startTime, addMinutes(exactDropTime, eventDuration)),
				title:eventTitle,
				track:trackId
			})
		}
		console.log('EVENTS',events)
	}
}

const handleExtendDrop = (e)=>{
	console.log('extend',e)
}

function onDrag(e) {

	
}

const extendEventStart = (e)=>{
	e.stopPropagation();
	let obj = e.target;
	console.log('obj.extendEventId',obj?.getAttribute("data-extendEventId"))
	const droppedTrackId = e.target.closest('.track')?.getAttribute("data-track-id");
	const droppedDate = e.target.closest('.date-column')?.getAttribute("data-date");
	let extendData = {
		extendEventDate:droppedDate,
		extendEventId:obj?.getAttribute("data-extendEventId"),
		extendTrackId:obj?.getAttribute("data-extendTrackId"),
		extendDroppedTrackId:droppedTrackId
	};
	
	        let jsonData = JSON.stringify(extendData);
	        e.dataTransfer.setData("text", jsonData);
	console.log('extendData',jsonData)
	
	
}

const getIdsInRange = (eventId, startId, endId, date) => {
	const dateObj = dates.value.find(item => item.date === date);
	const eventObj = events.find(e => e.id == eventId);
	console.log("eventObj",eventObj)
	console.log("55dateObj",dateObj)
	const startIndex = dateObj.tracks.findIndex(track => track.id == startId);
	const endIndex = dateObj.tracks.findIndex(track => track.id == endId);
	console.log("55startId",startId)
	console.log("55endId",endId)
	console.log("55startIndex",startIndex)
	console.log("55endIndex",endIndex)
	if (startIndex === -1 || endIndex === -1 ) {
		throw new Error("Invalid range");
	}
	let range = [];
	let filteredId = [];
	let existingEvent = events.find(e => e.id == eventId);
	if (startIndex < endIndex) {
		// const dateObj = dates.value.find(item => item.date === droppedDate);
		// let droppedIndex = dateObj.tracks.findIndex(track => track.id == trackId);
		// let lastIndex = 
		console.log("Asc Index",startIndex)
		range = dateObj.tracks.slice(startIndex, endIndex + 1);
		console.log("Asc range",range)
		
		
		existingEvent.track =  range.map(track => track.id);
		console.log("Asc existingEvent.track",existingEvent.track)

		const trackIndexArray = [];
	


		if (startIndex > endIndex) {
			console.log("reverse enabled")
			range = dateObj.tracks.slice(endIndex, startIndex + 1);
		   range.map(track => track.id).forEach((item)=>{
				console.log('Desc item',item)
				if(!existingEvent.track.includes(item)){
					console.log("existingEvent.track.includes(item)",existingEvent.track.includes(item))
					existingEvent.track.push(item)
				}
			});
		
		}

	
  

	

		// if (existingEvent) {
		// 	// Update the existing event
		
		// 	existingEvent.track = filteredId;
		
	// }
	}
}

const allowExtendFrontDrag = (item, event,trackId)=>{
	// console.log("check id",trackId,typeof(trackId))
	// console.log("event.track Array",event.track)
	// const dateObj = dates.value.find(x => x.date === item.date);
	// const index = dateObj.tracks.findIndex(y => y.id == trackId);
	
	// const trackIndexArray = [];
	
	// for(let i=0;i<dateObj.tracks.length;i++){
	// 	if(event.track.includes(dateObj.tracks[i].id)){
	// 		trackIndexArray.push(dateObj.tracks[i])
	//   }
	// }
	// console.log("trackIndexArray",trackIndexArray)
	// const trackIndex = trackIndexArray.findIndex(z => z.id == trackId);
	// console.log("index ",index,'trackIndex',trackIndex )
	// if(index != 0 && trackIndex == 0 ){
	// 	return 'd-block';
	// }
	return 'd-none';
}

const allowExtendDrag = (item,event,trackId)=>{
	const dateObj = dates.value.find(x => x.date === item.date);
	// const index = dateObj.tracks.findIndex(y => y.id == trackId);
	
	const trackIndexArray = [];
	
	for(let i=0;i<dateObj.tracks.length;i++){
		if(event.track.includes(dateObj.tracks[i].id)){
			trackIndexArray.push(dateObj.tracks[i])
	  }
	}
	console.log("trackIndexArray",trackIndexArray)
	const trackIndex = trackIndexArray.findIndex(z => z.id == trackId);
	console.log('trackIndex',trackIndex )
	if( trackIndex +1 == event.track.length ){
		return 'd-block';
	}
	return 'd-none';
	
//	return event.track.length === event.track.findIndex(id => id == trackId) +1?'d-block':'d-none' 
}



const calculateDuration = (startTime, endTime, title ) =>{
	if (!startTime || !endTime) return 0;

	const start = moment(startTime, "HH:mm");
	const end = moment(endTime, "HH:mm")
    console.log(title+'=>',start,end)
	const duration = moment.duration(end.diff(start)).asMinutes(); // Duration in minutes
	return duration;
}


const eventStyles = (event) =>{
	console.log('eventStyles',event)
	
	const start = moment(event.start, "YYYY-MM-DD HH:mm");
	const end = moment(event.end, "YYYY-MM-DD HH:mm");
	const duration = moment.duration(end.diff(start)).asMinutes();
	console.log('start',start)
	console.log('eventData',event)

	const eventHeight = (duration / timeStep.value) * timeCellHeight.value; // Assuming each time slot represents 15 minutes
	console.log("topPOSITION for",event.title,calculateTopPosition(event.start))
	console.log("eventHeight for",event.start,eventHeight)
	console.log("get POSITION",{
		position: "absolute",
		top: calculateTopPosition(event.start),
		height: `${eventHeight}px`,
	})
	return {
		position: "absolute",
		top: calculateTopPosition(event.start),
		height: `${eventHeight}px`,
	};
}

const calculateTopPosition = (startTime) =>{
	console.log("startTime",startTime)
	const start = moment(startTime, "YYYY-MM-DD HH:mm");
	
	const startHour = start.hours();
	const startMinute = start.minutes();
	
	const startMinutesFromMidnight = startHour * 60 + startMinute;
	const timeRangeStartMinutes = timeRange.value.start * 60; // 8:00 AM
	const minutesIntoRange = startMinutesFromMidnight - timeRangeStartMinutes;
	const topPosition = (minutesIntoRange / timeStep.value) * timeCellHeight.value;
	 // Assuming each time cell has a height of 40px
	//   console.log("startMinutesFromMidnight",startMinutesFromMidnight)
	//   console.log("height",(startMinutesFromMidnight / 30) * timeCellHeight  + "px")
	return topPosition  + "px"; // Assuming each time slot represents 15 minutes

   

}



const filteredEvents = (date,trackId)=>{
	return events.filter(event => isoDateformat(event.start) == date && event.track.includes(trackId));
}

const addMinutes = (time, minutes)=>{
	const t = moment(time, 'HH:mm');
	const newTime = t.add(minutes, 'minutes');
	return newTime.format('HH:mm');
}






const onResizeMouseDown = (event, eventData)=> {
	console.log("CLIKECD")
	currentEvent.value = eventData;
	initialY.value = event.clientY;
	
	initialHeight.value = event.target.parentElement.clientHeight; // get the height of the event element
	console.log('resize-handle', event.target.closest(".resize-handle"));
	console.log("initialHeight.value",initialHeight.value);
	document.addEventListener('mousemove', onResizeMouseMove);
	document.addEventListener('mouseup', onResizeMouseUp);
	document.addEventListener('mousedown', mouseDown);
	document.addEventListener('mouseout', mouseOut);
}


const mouseDown = (event) =>{
	console.log('mouseDown',event)
}

const mouseOut = (event) =>{
	console.log('mouseOut',event)
	event.target.closest(".topic-card").setAttribute("draggable", false);
	document.removeEventListener('mousemove', onResizeMouseMove);
	document.removeEventListener('mouseup', onResizeMouseUp);
}


const onResizeMouseMove = (event)=> {
	event.target.closest(".topic-card").setAttribute("draggable", false);
	console.log("initialHeight.value",initialHeight.value)
	if (!currentEvent.value) return;
	console.log('event.clientY',event.clientY,'initialY.value',initialY.value)
	const deltaY = event.clientY - initialY.value;
	console.log("deltaY",deltaY);
	const newHeight = initialHeight.value + deltaY;
	console.log("newHeight",newHeight);
	if (newHeight > 40) { // minimum height for an event
		const newDuration = Math.round(newHeight / minuteHeight.value); // calculate new duration
		currentEvent.value.duration = newDuration;
		currentEvent.value.end = `${isoDateformat(calculateNewEndTime(currentEvent.value.end, newDuration))} ${formatTime(calculateNewEndTime(currentEvent.value.end, newDuration))}`;
		console.log('currentEvent.value',currentEvent.value);
		console.log("newDuration",newDuration)
	}
}

const onResizeMouseUp =(event) =>{
	currentEvent.value = null;
	document.removeEventListener('mousemove', onResizeMouseMove);
	document.removeEventListener('mouseup', onResizeMouseUp);
}

const calculateNewEndTime = (end, duration)=>{
	const endTime = new Date(end);
	endTime.setMinutes(endTime.getMinutes() + Math.round(duration));
	return endTime;
}

onBeforeMount(() => {
	schedulerHeight.value = `height:${window.innerHeight - 148}px`;
})

  
</script>

<style scoped>

.topic-section{
  width: 33%;
    height: 100vh;
    border-radius: 0 12px 0 0;
    border: 1px solid #CACACA;
    border-bottom: 0;
    border-left: 0;
    }
    .topic-action{border-bottom: 1px solid #9E9E9E;
    margin: 10px;display: flex;justify-content: space-between;align-items: center;
    padding-bottom: 10px;}  
    .topic-list{height: 450px;overflow-y:auto;}
	.scheduler-height{overflow-y:auto;}
    .topic-list::-webkit-scrollbar-track,.scheduler-height::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.topic-list::-webkit-scrollbar,.scheduler-height::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}
.topic-list::-webkit-scrollbar-thumb,.scheduler-height::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
    .add-event{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-event .fa-plus{margin-right: 8px;}

.scheduler{width: 70%;margin: 0 15px;position: relative;}
.scheduler-header {
	display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 10px 20px 0px #C3C3C340;
    margin: 13px 0;
    border-radius: 5px;
    padding: 10px 24px;
	font-family: 'Mulish', sans-serif;
}
.scheduler-header > h1{font-size: 26px;}
.scheduler-navigation{display: flex;align-items: center;background: #F5F5F5;border-radius: 5px;font-size: 14px;}
.scheduler-navigation > button{color:#707070;}
.scheduler-navigation > span{margin-bottom: -3px;}
.scheduler-navigation > button > .fa-chevron-left,.scheduler-navigation > button > .fa-chevron-right{font-size: 14px;}
#dateDisplay {
    padding: 10px;
    font-size: 1.2em;
    margin: 0 10px;
}

button {
    padding: 10px;
    font-size: 1.2em;
    cursor: pointer;
}

/* custom scheduler */
.main{display: flex;}
.topic-card{box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 100%;
    height: 100px;
    background: white;
    z-index: 1020;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 12px;
    padding:6px 11px;}
	.resize-handle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  cursor: ns-resize;
  background: rgba(0, 0, 0, 0.1); /* optional for visibility */
}
/* .topic-card:hover .extend-event{opacity: 1;} */
.extend-event-front,.extend-event-back{
	/* opacity: 0; */
	position: absolute;
    top: 0;
    bottom: 0;
    
    width: 10px;
    height: 100%;
    z-index: 1020;
    background: #9e9e9e61;
    cursor: col-resize;
    border-radius: 0 12px 12px 0;
}
.extend-event-front{left: 0;border-radius: 12px 0 0 12px}
.extend-event-back{right: 0;}
/* .topic-card:nth-child(1){background-color: greenyellow;} */

 .external-event{position: relative;margin-bottom: 12px;}   
.topic-title{font-size: 18px;font-weight: 700;
    font-family: 'Mulish', sans-serif;}    
.time-block{display: flex;font-size:14px;color: gray;
  font-weight: 700;}    
.date-slide-container{width: 100%;overflow-x: auto;
	scroll-behavior: smooth;display:flex;flex-direction: row; }
		.date-slide-container::-webkit-scrollbar {
  display: none;
}
.date-column{ flex: 0 0 auto;transition:  0.8s ease;position: relative;}
.date-column:hover{box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;border: 1px transparent;transition: .4s ease-in-out;}
.time-cell-label{font-family: 'Mulish', sans-serif;}
.add-track{position: absolute;
    right: 0px;
    top: 344px;
    padding: 0px 12px 0px 0px;
    background-color: #007cba;
    z-index: 1040;
    display: none;
    align-items: center;
    border-radius: 16px 1px 16px 16px;
    color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 16px;

    transition: all 0.6s ease-in;}
				.add-track span{color: #fff;
					font-size: 28px;
					padding: 0px 4px 1px 11px;}
          .date-column:hover .add-track{display: flex;}          
.date-label{text-align: center;
    font-size: 14px;
    margin: 3px 0 4px;
    font-family: 'Poppins', sans-serif;}
 .track-container{display: flex;width: max-content;
  height: 93%;}
 .track{flex: 0 0 auto;width:200px;position: relative;} 
 .track-container > .track{border-right:1px solid #a5a2a2;}
 .track-container > .track:nth-last-child(1){border-right:unset;}
 .track-label{text-align: center;
    font-size: 16px;
    margin-top: -5px;
    font-weight: 600;
	font-family: 'Mulish',sans-serif;}
 .track-cell{height: 100%;position: relative;}     
 
.active{box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;opacity: 1;
	display: block;
    /* position: absolute;
    z-index: 1020;
    height: 393px;
    left: 0; */
}
.scheduler-body{position: relative; display: flex;width: 100%;border: 1px solid #e7e7e7;
    box-shadow: 0px 10px 20px 0px #C3C3C340;
	
	/* max-height: 450px; */
}
.time-column{font-size: 14px;margin:53px 12px 0px;}
.time-column .time-cell-line{color: #999;
    text-align: right;
    padding-right: 2px;
    font-size: .9em;}
.time-column .time-cell-line:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-top:1px dashed rgb(179 177 177 / 48%);
    z-index: 1020;
}

</style>

