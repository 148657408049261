<template>
  <Form
    ref="form"
    :initial-values="initialData"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <table>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Middle Name</th>
          <th>Last Name</th>
          <th>Gender</th>
          <th>Phone Number<span>*</span></th>
          <th>Email</th>
          <th>Chapter<span>*</span></th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <FieldArray
          v-slot="{ fields, push, remove }"
          name="members"
        >
          <tr
            v-for="(field, idx) in fields"
            :key="field.key"
          >
            <td>
              <Field
                :id="`firstName_${idx}`"
                type="text"
                :name="`members[${idx}].firstName`"
              />
              <ErrorMessage :name="`members[${idx}].firstName`" />
            </td>
            <td>
              <Field
                :id="`middleName_${idx}`"
                type="text"
                :name="`members[${idx}].middleName`"
              />
              <ErrorMessage :name="`members[${idx}].middleName`" />
            </td>
            <td>
              <!-- <input type="text" v-model="field.value.lastName"> -->
              <Field
                :id="`lastName_${idx}`"
                type="text"
                :name="`members[${idx}].lastName`"
              />
              <ErrorMessage :name="`members[${idx}].lastName`" />
            </td>
            <td>
              <Field
                v-slot="{ field }"
              
                type="text"
                :name="`members[${idx}].gender`"
              >
                <select
                  :id="`gender_${idx}`"
                  v-bind="field"
                >
                  <option
                    value=""
                    selected
                  >
                    Gender
                  </option>
                  <option value="Male">
                    Male
                  </option>
                  <option value="Female">
                    Female
                  </option>
                  <option value="Other">
                    Other
                  </option>
                </select>
              </Field>
      
              <ErrorMessage :name="`members[${idx}].gender`" />
            </td>
            <td>
              <Field
                :id="`phoneNumber_${idx}`"
                :name="`members[${idx}].phoneNumber`"
              >
                <vue-tel-input
                  v-model="field.value.phoneNumber"
                />
              </Field>
              <ErrorMessage :name="`members[${idx}].phoneNumber`" />
            </td>
            <td>
              <Field
                :id="`email_${idx}`"
                type="text"
                :name="`members[${idx}].email`"
              />
              <ErrorMessage :name="`members[${idx}].email`" />
            </td>
            <td>
              <Field
                :id="`chapter_${idx}`"
                type="text"
                :name="`members[${idx}].chapter`"
              />
              <ErrorMessage :name="`members[${idx}].chapter`" />
            </td>
            <td>
              <fa
                icon="circle-plus"
                class="pointer"
                @click="push({'firstName': '',
                              'middleName':'',
                              'lastName': '',
                              'gender':'',
                              'phoneNumber': '',
                              'email': '',
                              'chapter':''})"
              /> <fa
                icon="trash"
                class="pointer"
                @click="remove(idx)"
              />
            </td>
          </tr>
        </FieldArray>
      </tbody>
    </table>
    <div class="form-actions">
      <button
        type="button"
        @click="resetForm"
      >
        Reset
      </button>
      <button type="submit">
        Save
      </button>
    </div>
  </Form>

  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>





<script setup>
import { isValidPhoneNumber } from "libphonenumber-js/mobile"; 
import {ref} from 'vue';
import {Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import { useStore } from 'vuex';

const store = useStore(); 

const form  = ref();
const dialCode = ref('');
const	timeout= ref(2000);

// const chapter = ref('');
const snackbar= ref(false);
const text= ref('');
// let isValid = ref(false);
const initialData = {
	members: [{'firstName': '',
		'middleName':'',
		'lastName': '',
		'gender':'',
		'phoneNumber': '',
		'email': '',
		'chapter':''}]
};

const schema = yup.object().shape({
	members: yup
		.array()
		.of(
			yup.object().shape({
				phoneNumber:yup.string().required('Please enter phone number').label("phoneNumber").test('custom-validation', 'Invalid phone number', function(value) {
					console.log('value',value,isValidPhoneNumber(value));
					if (!isValidPhoneNumber(value)) {
						return false; // Validation failed
					}
					return true;
				}),
				email: yup.string().email('Invalid email address').label("email"),
				chapter: yup.string().required('Please enter chapter').label("chapter")
			})
		)
		.strict(),
});

  



const onSubmit = (data) => {
	

	const membersData = data.members.map(({ firstName, middleName, lastName, gender, phoneNumber, email, chapter }) => ({
  
		"name": {
			"first": firstName,
			"middle":middleName,
			"last": lastName
		},
		"email": email,
		"phone": {
			"dialCode": '+91',
			"number":phoneNumber.replace(phoneNumber.split(' ')[0],'').replace(/\D/g, '')
		},
		"gender":gender,
		"state":chapter  

	}));


	console.log("membersData",membersData);
	axios
		.post(`${process.env.VUE_APP_SERVICE_URL}/member`,
			membersData,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
          
				snackbar.value=true;
				text.value = "Added Successfully"; 
				form.value.resetForm();
			}
		})
};



const resetForm = ()=>{
	form.value.resetForm()  
}
 

  

 


    

</script>

<style scoped>
table {
  width: 100%;
  border-radius: 40px!important;
  border-spacing: 0;
  border-collapse: separate;
  border: unset;
}

table  td, th {
  border: 1px solid #dddddd;
  padding: 8px;
}
thead > tr > th{font-weight: 500;text-align: left;padding: 12px 9px;border-bottom:unset;border-right: unset;}
thead > tr > th:nth-child(1){border-radius: 12px 0 0 0;}
thead > tr > th:last-child{border-radius:  0 12px 0 0;border-right: 1px solid #dddddd; }

thead > tr > th span{color:red;}


tbody > tr > td{padding:0}
tbody > tr:not(:last-child) td{border-bottom: unset;border-right: unset;}
tbody > tr:not(:last-child) td:last-child{border-right: 1px solid #dddddd;}
tbody > tr > td input,tbody > tr > td select{height:43px;width: 100%;padding: 12px 9px;}
tbody > tr > td input:focus,tbody > tr > td select:focus{outline: unset;}
tbody > tr > td span,tbody > tr > td .error-msg{color: #FF0000;font-size: 12px;text-align: left;margin-left: 11px;}
tbody > tr > td .fa-trash{color:#FF0000;text-align: center;cursor: pointer;}
tbody > tr > td .fa-circle-plus{color:#8BC34A;cursor: pointer;}
.vue-tel-input{border:unset!important;}
.vue-tel-input:focus-within{
-webkit-box-shadow: none!important;
  box-shadow: none!important;}
 

/* curve */
tbody > tr:last-child td:nth-child(1){border-radius:  0 0 0 12px;}
tbody > tr:last-child td:last-child{border-radius:  0 0 12px  0;border-right: 1px solid #dddddd;text-align: center;}
/* curve */
tbody > tr:last-child td{border-bottom: 1px solid #dddddd;border-right: unset;}

#addMember{border-radius: 5px;
    background: #007CBA;
    color: #fff;
    padding: 8px;
    margin-left: auto;
    margin-top: 12px;
    display: block;}

.form-actions{display: flex;justify-content: flex-end;margin-top: 22px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
</style>

