<template>
  <EditSchedule
    :event-dates="store.state.event.eventDates"
    :editable-event="editableEventData"
    :mode="store.state.event.scheduleFormMode"
    :event-id="eventId"
  />
  <AddTrackForm
    :visible="addTrackDialog"
    :title="formTitle"
    :confirm-text="'Save'"
    :button-bg="'info'"
    :get-track-name="editTrackName?editTrackName:''"
    @cancel="addTrackDialog = false"
    @submit="submitTrackDetail"
  />


  <div class="wrap">
    <div class="content">
      <vue-cal
        ref="getCal"
        :disable-days="setMissingDates()"
        :selected-date="minDate" 
        :min-date="minDate"
        :max-date="maxDate"
        small
        active-view="day"
        :time-from="8 * 60"
        :time-step="30"
        :disable-views="['years', 'year', 'month','week']"
        :events="store.state.event.events"
        :split-days="splitDays"
        :min-cell-width="minCellWidth"
        :min-split-width="minSplitWidth"
        :editable-events="{ title: false, drag: true, resize: true,delete: false, create: false }"
        sticky-split-labels
    
        style="height: 450px;"
		
        @view-change="onViewChange"
        @event-drop="onEventDrop"
      >
        <template #split-label="{ split, view }">
          <strong>{{ split.label }}</strong>
          <div class="option-container">
            <div 
              class="ellipsis-icon pointer enable-option"
              :data-show-track-id="split.id"
              @click="showTrackOption($event)"
            >
              &nbsp; &#8942;
            </div>

            <div
              :id="'option-dropdown-'+split.id"
              class="option-dropdown"
              :data-dropdown-id="split.id"
            >
              <div
                class="disable-option pointer"
         
                :data-hide-track-id="split.id"
                @click="hideTrackOption($event)"
              >
                &#128473;
              </div>
              <div
                class="menu-item pointer edit-track"
                :data-id="split.id"
                :data-name="split.label"
              >
                <fa 
                  icon="pen-to-square"
                />Edit
              </div>
              <div
                class="menu-item pointer delete-track" 
                :data-id="split.id"
                :data-name="split.label"
                @click="deleteTrack"
              >
                <fa 
                  icon="trash"
                />Delete 
              </div>
            </div>
          </div>
        </template>
        <template #event="{ event, view }">
          <!-- <div class="event-container"> -->
          <!-- <div class="color-bar" />	 -->
          <!-- <div class="event-content">	 -->
          <div class="vuecal__event-option">
            <div class="event-option-container">
              <div 
                :focusable="false"
                class="ellipsis-icon pointer"
                :data-eEnabledId="event._eid"
                @click="enableEventOption($event)"
              >
                &#8942;
              </div>
              <div
                :id="'event-option-dropdown-'+event._eid"
                class="event-option-dropdown"
              >
                <div
                  class="close-icon pointer"
                  :data-eCloseId="event._eid"
                  @click="disableEventOption"
                >
                  &#128473;
                </div>
                <div
                  class="menu-item pointer edit-event"
                  :data-e-edit-id="event.id"
                  :data-event-name="event.title"
                  :data-start-time="event.start"
                  @click="editEvent"
                >
                  <fa 
                    icon="pen-to-square"
                  />Edit
                </div>
                <div
                  class="menu-item pointer delete-event" 
                  :data-eDeleteId="event.id"
                  @click="deleteEvent"
                >
                  <fa 
                    icon="trash"
                  />Delete
                </div>
              </div>
            </div>
          </div>	
          <div class="vuecal__event-title">
            {{ event.title }}
          </div>
          <div class="vuecal__event-time">
            {{ formatTime(event.start) }}<span>&nbsp;to {{ formatTime(event.end) }}</span><!---->
          </div>
          <!-- </div> -->
          <!-- </div> -->
        </template>
      </vue-cal>
      <div class="schedule-action">
        <button
          class="save-as-draft"
          @click="saveAsDraft"
        >
          Save as Draft
        </button>
        <div class="error" />
        <div style="display:flex;">
          <div class="error-message">
            <fa 
              class="pointer"
              icon="circle-exclamation"
            />
            <div
			 
              v-show="errors.length > 0"
              class=" error-dialog"
            >
              <fa
                class="close-error-dialog pointer"
                icon="xmark"
                @click="errors=[]"
              />
              <ul>
                <li
                  v-for="(item,index) in errors"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </div> 
          </div>
		  
          <button
            class="publish-event"
            @click="publishEvent"
          >
            Publish Now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, provide, onMounted, onBeforeMount, computed, onUpdated, watch } from 'vue';
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import moment from 'moment';
import { useStore } from 'vuex';
import { isoDateformat, formatTime, isoStringDateformat } from '@/helper/index.js';
import { storeTrack, getEventData, getTrackData, removeTrackDetails, addEventTopic, updateSchedule, getSchedule, removeTopic, getProfile} from '@/service/helper-service.js';
import EditSchedule from '@/components/event_management/scheduler/EditSchedule.vue'
import AddTrackForm from '@/components/event_management/scheduler/AddTrackForm.vue'

const store = useStore();
const currentDate = ref('');
const trackFormMode = ref('add');

let EditScheduleDialog = reactive({'status':false});
provide('EditScheduleDialog',EditScheduleDialog)

const props = defineProps({
	eventId:{
		type:String,
		required:false,
		default:''
	}
})



const eventDays = ref([
	// {'date':'2024-06-02','tracks':[
	// { id: 1, class: 'pink-bg',color:'orange', label: 'Track 1' }
	// ]},
	// {'date':'2024-06-04','tracks':[]},
	// {'date':'2024-06-06','tracks':[]}
])





const getCal = ref(null);
const minCellWidth = ref(300);
const minSplitWidth = ref(50);


const draggables = ref(props.queueTopics)
console.log("draggablesOnload",draggables)

const splitDays = ref([])
const addTrackDialog = ref(false); 
const editTrackName = ref(''); 
const editTrackId = ref(''); 
const formTitle = ref('Add Track'); 
const editableEventData = ref({})
let errors = ref([]);
let errorMessage = ref('');





let events = reactive(...store.state.event.events)
// let events = reactive([
// {
// 	id:1,
// 	start: '2024-06-02 08:10',
// 	end: '2024-06-02 09:30',
// 	title: 'Doctor appointment',
// 	content: '<i class="icon material-icons">local_hospital</i>',
// 	split: 1 
// },
// {
// 	id:2,
// 	start: '2024-06-02 09:45',
// 	end: '2024-06-02 10:30',
// 	title: 'Music',
// 	content: '<i class="icon material-icons">local_hospital</i>',
// 	split: 1 
// },
// {description: "",
// 	duration: 120,
// 	end: "2024-06-18 15:34",
// 	eventId: "666d321f798e76001394c387",
// 	hallId: "65dc979f4268210011d25022",
// 	id: "66718a81c3a1f40013e0bc99",
// 	keywords: "TEST",
// 	speakers: [],
// 	split: "666d3365798e76001394c403",
// 	start: "2024-06-18 13:34",
// 	tag: "",
// 	title: "TITLE",
// 	type: "draft"}
// ])

					






const onEventDrop  = ({ event, originalEvent, external })=>{
	// If the event is external, delete it from the data source on drop into Vue Cal.
	// If the event comes from another Vue Cal instance, it will be deleted automatically in there.
	if (external) {
		const extEventToDeletePos = store.state.event.topics.findIndex(item => item.id === originalEvent.id)
		if (extEventToDeletePos > -1) store.state.event.topics.splice(extEventToDeletePos, 1)
	}
	console.log("originalEvent",event);
	// Assuming event is defined elsewhere
	let existingEvent = store.state.event.events.find(e => e.id == event.id);
	console.log("existingEvent",existingEvent)
	if (existingEvent) {
		// Update the existing event
		existingEvent.start = `${isoDateformat(event.start)} ${formatTime(event.start)}`;
		existingEvent.end = `${isoDateformat(event.end)} ${formatTime(event.end)}`;
		existingEvent.title = event.title;
		existingEvent.tag = event.tag;
		existingEvent.description = event.description;
		existingEvent.keywords = event.keywords;
		existingEvent.hallId = event.hallId;
		existingEvent.speakers = event.speakers;
		existingEvent.split = event.split;
		existingEvent.type = event.type;

		console.log("drag isoStringDateformat",isoStringDateformat(event.start))
	} else {
		// Add a new event
		store.commit('event/addEvents',{
			id: event.id,
			start: `${isoDateformat(event.start)} ${formatTime(event.start)}`,
			end: `${isoDateformat(event.end)} ${formatTime(event.end)}`,
			duration:event.endTimeMinutes - event.startTimeMinutes,
			title: event.title,
			tag: event.tag,
			description: event.description,
			keywords: event.keywords,
			hallId: event.hallId,
			speakers: event.speakers,
			...(event?.speakerDetails && { speakerDetails: event?.speakerDetails }),
			split: event.split,
			type: event.type
		});
		
	}


	console.log("dragged event",store.state.event.events)
 
}

const onViewChange = ({ startDate, endDate }) =>{
	console.log('startDate',isoDateformat(startDate))
					

	setTracks(isoDateformat(startDate))
	setTracks(isoDateformat(endDate))
}
					

const minDate = computed(()=>{
	return eventDays.value.length > 0 && eventDays.value?.[0]?.date;
})

const maxDate = computed(()=>{
	return  eventDays.value.length > 0 && eventDays.value[eventDays.value.length - 1].date;
})

onBeforeMount(()=>{
	loadSchedule(props.eventId)
	getEvent(props.eventId)
	
	 
})


onMounted(() => {
	// check any track is available

	// loadSpeakerImage('64903e112aa77f49e02961fc')
				
	  
	if(!getCal.value.$el.querySelector('.vuecal__cell--disabled')){
		let referenceNode = getCal.value.$el.querySelector('.vuecal__cell');
		const newNode = document.createElement('button');
		newNode.classList.add('add-track');
		newNode.innerHTML = '<span>&#xFF0B;</span> Add Track';
		referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
	}

	for(let i=0;i < splitDays.value.length;i++){
		console.log('class',splitDays.value[i].class);
	}
	
		
	
	generateTrackCss()
		
});

onUpdated(()=> {

	let referenceNode = getCal.value.$el.querySelector('.vuecal__cell');
	const newNode = document.createElement('button');
	newNode.classList.add('add-track');
	newNode.innerHTML = '<span>&#xFF0B;</span> Add Track';

	if(getCal.value.$el.querySelector('.vuecal__cell--disabled')){
		console.log("Disabled")
		if(referenceNode?.nextSibling?.tagName?.toLowerCase() === 'button'){
			referenceNode.nextSibling.remove();
		}
	}else{
		console.log("Enabled")
		if(referenceNode?.nextSibling?.tagName?.toLowerCase() === undefined){
			referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
		}
		referenceNode?.nextSibling?.addEventListener('click',()=>{
			addTrack()
		})
						
						
		const splitLabels = getCal.value.$el.querySelectorAll('.split-label');
		const enableOptionElems = getCal.value.$el.querySelectorAll('.enable-option');
		const disableOptionElems = getCal.value.$el.querySelectorAll('.disable-option');
		const editTrackElems = getCal.value.$el.querySelectorAll('.edit-track');
						
						
				
		// // enableOption
		// enableOptionElems.forEach((enableOptionElem,index) => {
		// 	enableOptionElem.addEventListener('click', () => {
		// 		console.log("clicked track")
		// 		let dataId = enableOptionElem.getAttribute('data-id');
		// 		if(getCal.value.$el.querySelector('#option-dropdown-'+dataId).classList.contains('d-none')){
		// 			getCal.value.$el.querySelector('#option-dropdown-'+dataId).classList.remove('d-none')
		// 		}
		// 		getCal.value.$el.querySelector('#option-dropdown-'+dataId).classList.add('d-block')
		// 	});
		// });	

		// // disableOption
		// disableOptionElems.forEach((disableOptionElem,index) => {
		// 	disableOptionElem.addEventListener('click', () => {
		// 		console.log('index',index)
		// 		if(getCal.value.$el.querySelectorAll('.option-dropdown')[index].classList.contains('d-block')){
		// 			getCal.value.$el.querySelectorAll('.option-dropdown')[index].classList.remove('d-block')
		// 		}
		// 		getCal.value.$el.querySelectorAll('.option-dropdown')[index].classList.add('d-none')
		// 	});
		// });	
						

						

		// editTrackElems
		editTrackElems.forEach((editTrackElem ,index)=> {
			editTrackElem.addEventListener('click', () => {
				// const index = splitDays.value.findIndex(item => item.id == deleteTrackElem.getAttribute('data-id'));
								
				formTitle.value = 'Edit Track';
				trackFormMode.value = 'edit';
				addTrackDialog.value = true;
				editTrackName.value = editTrackElem.getAttribute('data-name')
				editTrackId.value = editTrackElem.getAttribute('data-id');
			});
		});	

		//Track class
		generateTrackCss()

	}

	
   


	console.log("Updateng");
})


const showTrackOption = (e)=>{
	let dataId = e.target.getAttribute('data-show-track-id')
	getCal.value.$el.querySelector('#option-dropdown-'+dataId).style.display = "block";
}

const hideTrackOption = (e)=>{
	let dataId = e.target.getAttribute('data-hide-track-id')
	getCal.value.$el.querySelector('#option-dropdown-'+dataId).style.display = "none";
}



const enableEventOption = (e)=>{
	let dataId = e.target.getAttribute('data-eEnabledId')
	getCal.value.$el.querySelector('#event-option-dropdown-'+dataId).style.display = "block";
}

const disableEventOption = (e)=>{
	let dataId = e.target.getAttribute('data-eCloseId');
	getCal.value.$el.querySelector('#event-option-dropdown-'+dataId).style.display = "none";

}

const deleteEvent = async(e)=>{
	let deleteId = e.target.getAttribute('data-eDeleteId');
	console.log(e.target.getAttribute('data-eDeleteId'));
	console.log('events',events);
	


	try {
		const response = await removeTopic(`/schedule/${deleteId}`);
		if(response.status === 200){
			console.log('DelteE',response);
			let index = store.state.event.events.findIndex(event => event.id == deleteId);
			if (index !== -1) {
				store.state.event.events.splice(index, 1);
			}

			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deleted Successfully',
				color:'success',
				timeout:2000});
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
	
}

const editEvent = (e)=>{
	store.state.event.scheduleFormMode ='Edit';
	 e.target.getAttribute('data-e-edit-id');
	 let editableData = store.state.event.events.filter(event => event.id == e.target.getAttribute('data-e-edit-id')) 
	 console.log("editableData",editableData)
	 editableEventData.value = editableData;
	 console.log("???",store.state.event.events)
	// eventFormData.value.eventName = e.target.getAttribute('data-event-name');
	// eventFormData.value.eventStartTime = e.target.getAttribute('data-start-time');

	
	console.log(e.target.getAttribute('data-e-edit-id'))

	EditScheduleDialog.status = true;
	
}


const deleteTrack = async (e)=>{
	let deleteId = e.target.getAttribute('data-id');
	console.log(e.target.getAttribute('data-id'));
	console.log('events',events);
    
	try {
		const response = await removeTrackDetails(`/track/${deleteId}`);
		if(response.status === 200){
			console.log('Remove track response',response?.data?.data);
			let index = splitDays.value.findIndex(track => track.id == deleteId);
			if (index !== -1) {
				splitDays.value.splice(index, 1);
				getCal.value.$el.querySelector('#option-dropdown-'+deleteId).classList.add('d-none')
			}
			
		}
	} catch (error) {
		if (error.response) {
			console.log('GET EVENT',error.response);
		}
	}

}




const setTracks = (date)=>{
	currentDate.value = date;
	splitDays.value = [];
	// let getTracks = eventDays.value.filter(item => item.date == date);
	// splitDays.value = getTracks[0]?.tracks || [];
	// getUpdatedTrack(props.eventId,date)				
}


const addTrack = ()=>{
	trackFormMode.value = 'add';
	addTrackDialog.value = true;
}


const submitTrackDetail = (data,form,pureColor)=>{
	console.log("submitTrackDetai",data)
	console.log("form.value",form.value.values)
	
	
	if(trackFormMode.value !='edit'){
		createTrack(
			'post',
			'/track',
			{
				"name": data.trackName,
				"date": currentDate.value,
				"color": data.pureColor,
				"eventId": props.eventId
			})

						
	}else{
		createTrack(
			'put',
			`/track/${editTrackId.value}`,
			{
				"name": data.trackName,
				"date": currentDate.value,
				"color": data.pureColor,
				"eventId": props.eventId
			})

		
	}

	
					
	addTrackDialog.value = false;
}


const setMissingDates = ()=>{
	const existingDates = eventDays.value.map(event => event.date);
	const startDate = moment(existingDates[0]);
	const endDate = moment(existingDates[existingDates.length - 1]);
	const dateRange = [];
	let currentDate = startDate.clone();

	while (currentDate.isSameOrBefore(endDate, 'day')) {
		dateRange.push(currentDate.format('YYYY-MM-DD'));
		currentDate.add(1, 'day');
	}
	const missingDates = dateRange.filter(date => !existingDates.includes(date));
	return missingDates;
}


watch(events, (newValue,oldValue) => {
	console.log("old update event",newValue);
	console.log("latest update event",oldValue);
	
});


// let getTracks = eventDays.value.filter(item => item.date == eventDays.value?.[0]?.date);
// 			console.log('trackAvailable',getTracks.tracks,'eventDays.value',eventDays.value)

watch(currentDate, (newValue) => {
	console.log("CD",newValue);
	console.log("CD TRUE",eventDays.value.find(item => item.date == newValue))
	if(eventDays.value.find(item => item.date == newValue) !== undefined){
		getUpdatedTrack(props.eventId,newValue)
       
	}else{
		console.log("not found")
	}
	
	// console.log("eventDays.value",eventDays.value);
	//  let getTracks = eventDays.value.filter(item => item.date == newValue);
	//  console.log('getTracks',getTracks?.[0]?.tracks.length)		
});


const spliceFirstChar = (color)=>{
	return color[0] == '#'?color.slice(1):color;
}

const createTrack = async( method, path, payload)=>{
	try {
		const response = await storeTrack(method, path, payload);
		if(response.status === 200){
			console.log('response',response?.data?.data._id);
			let cTrack = response?.data?.data;
			if(method =='post' && cTrack?._id){
				splitDays.value.push({ id: cTrack?._id, class:'track-'+spliceFirstChar(cTrack?.color),color:cTrack?.color,label: cTrack?.name })
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Added track successfully',
					color:'success',
					timeout:2000});
				// form.value.resetForm()  
				// pureColor.value='orange';
			}

			if(method =='put'){
				const index = splitDays.value.findIndex(item => item.id == editTrackId.value);
				if (index !== -1) {
					splitDays.value[index].label = payload.name;
					splitDays.value[index].class = 'track-'+spliceFirstChar(payload.color);
					splitDays.value[index].name = payload.name;
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Updated track successfully',
						color:'success',
						timeout:2000});
				}
			}

			generateTrackCss()
			
			
		}
	} catch (error) {
		if (error.response) {
			console.log('response',error.response);
		}
	}
}


const getEvent = async(id)=>{
	try {
		const response = await getEventData(`/event/${id}`);
		if(response.status === 200){
			console.log('GET EVENT response',response?.data?.data?.[0]?.days);
			console.log(response?.data?.data?.[0]?.days.map(day=> ({ 'date': isoDateformat(day.date),'tracks':[]  })))
			eventDays.value = response?.data?.data?.[0]?.days.map(day=> ({ 'date': isoDateformat(day.date),'tracks':[] }))
			store.state.event.eventDates =  response?.data?.data?.[0]?.days.map(day=> (isoDateformat(day.date)))
			store.state.event.uniqeEventId = response?.data?.data?.[0]?.eventId;
			// if(eventDays.value?.[0]?.date){ 
			// setTracks(eventDays.value?.[0]?.date)
			
			// }

			
			
		}
	} catch (error) {
		if (error.response) {
			console.log('GET EVENT',error.response);
		}
	}
}

const getUpdatedTrack = async(id,date)=>{
	console.log('DATE FOUND',eventDays.value.find(item => item.date == date))
	try {
		const response = await getTrackData(`/track/${id}?date=${date}`);
		if(response.status === 200){
			console.log('response',response?.data?.data);
			 console.log('TRACKS',response?.data?.data?.map(day=> ({ id: day._id, class:'track-'+spliceFirstChar(day?.color),color:day?.color,label: day?.name })))
			// eventDays.value = response?.data?.data?.[0]?.days.map(day=> ({ 'date': isoDateformat(day.date),'tracks':[] }))
			splitDays.value = response?.data?.data?.map(day=> ({ id: day._id, class:'track-'+spliceFirstChar(day?.color),color:day?.color,label: day?.name }));
			
			console.log("SPLITDAYS Length",splitDays.value.length)
		   if(splitDays.value.length === 0){
		      createTrack(
					'post',
					'/track',
					{
						"name": 'Track 1',
						"date": currentDate.value,
						"color": 'orange',
						"eventId": props.eventId
					})
		   }
			
		}
	} catch (error) {
		if (error.response) {
			console.log('GET EVENT',error.response);
		}
	}
}


const loadSpeakerImage = async(id)=>{
	try {
		const response = await getProfile(`/profileUpload/${id}`);
		if(response.status === 200){
			console.log('loadSpeakerImage',response);
			
			
		}
	} catch (error) {
		if (error.response) {
			console.log('GET EVENT',error.response);
		}
	}
}

const saveAsDraft = async()=>{
	const drafEvents = store.state.event.events
		.filter(e => (e.type === 'pending' || e.type === 'draft' || e.type === 'published') && isoDateformat(e.start) == currentDate.value)
		.map(e => ({
			_id:e.id,
			title: e.title,
			description: e.description,
			tag: e.tag,
			keywords: e.keywords,
			date: isoDateformat(e.start),
			eventId: props.eventId,
			// ...(e.speakers.length > 0 || { speakers: e.speakers}),
			...(e.start && { startTime:isoStringDateformat(e.start)}),
			...(e.end && { endTime: isoStringDateformat(e.end) }),
			...(e.duration && { duration: e.duration }),
			...(e.split && { trackingId: e.split }),
			...(e.hall && { hallId: e.hall }),
			type:'draft'

		}));

	console.log("drafEvents", drafEvents);


	
	try {
		const response = await updateSchedule('put','/update/schedule',drafEvents);
		if(response.status === 200){
			console.log('Draftresponse',response?.data?.data);
		    store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Saved as Draft',
				color:'success',
				timeout:2000});
		}
	} catch (error) {

	}
}

const publishEvent = async()=>{
	
	errors.value = [];


	function validateField(field, fieldName) {
		if (!field || (Array.isArray(field) && field.length === 0)) {
			console.error(`Error: ${fieldName} is empty`);
			return false;
		}
		return true;
	}

	const publishData = store.state.event.events
		.filter(e => (e.type === 'pending' || e.type === 'draft' || e.type === 'published') && isoDateformat(e.start) == currentDate.value)
		.map(e => {
			

			if (!validateField(e.id, 'ID')) errors.value.push('ID is empty');
			if (!validateField(e.title, 'Title')) errors.value.push('Title is empty');
			if (!validateField(e.description, 'Description')) errors.value.push('Description is empty');
			if (!validateField(e.tag, 'Tag')) errors.value.push('Tag is empty');
			if (!validateField(e.keywords, 'Keywords')) errors.value.push('Keywords is empty');
			if (!validateField(e.start, 'Start Date')) errors.value.push('Start Date is empty');

			// Log all errors.value
			if (errors.value.length > 0) {
				console.error(`Event ID ${e.id} has the following validation errors:`, );
				// errorMessage.value = `Event ID ${e.id} has the following validation errors:<ul><li></li></ul> ${errors.value.join(', ')}`;
				return null;
			}
			console.log("errors",errors.value)
			return {
				_id: e.id,
				title: e.title,
				description: e.description,
				tag: e.tag,
				keywords: e.keywords,
				date: isoDateformat(e.start),
				eventId: props.eventId,
				...(e.start && { startTime: isoStringDateformat(e.start) }),
				...(e.end && { endTime: isoStringDateformat(e.end) }),
				...(e.duration && { duration: e.duration }),
				...(e.split && { trackingId: e.split }),
				...(e.hall && { hallId: e.hall }),
				type: 'published'
			};
		})
		.filter(e => e !== null); // Filter out invalid events

	console.log('publishData',publishData)
	
	try {
		const response = await updateSchedule('put','/update/schedule',publishData);
		if(response.status === 200){
			console.log('Draftresponse',response?.data?.data);
		    store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Published Successfully',
				color:'success',
				timeout:2000});
		}
	} catch (error) {

	}
}



const loadSchedule = async(id)=>{
	try {
		const response = await getSchedule(`/schedule?eventId=${id}`);
		if(response.status === 200){
			console.log('Load Schedule',response);
	
			let mappedTopics = response?.data?.data.map(topic => ({ 'id':topic._id,
				'title':topic.title,
				'tag':topic.tag,
				'description':topic.description,
				'keywords':topic.keywords,
				'eventId':topic.eventId,
				'hallId':topic.hallId,
				'speakers':topic.speakers,
				'start': `${isoDateformat(topic.startTime)} ${formatTime(topic.startTime)}`,
				'end': `${isoDateformat(topic.endTime)} ${formatTime(topic.endTime)}`,
				'duration':topic.duration,
				...(topic.trackingId && { split: topic.trackingId }),
				'type':topic.type
			}))


			store.state.event.events = [...mappedTopics];
			

			console.log('LOAD EVENTS',store.state.event.events)
			

			// store.state.event.topics = mappedTopics;
			// topicList.value = mappedTopics;
			// console.log('Mapped',mappedTopics)
			// console.log("topicList.value",store.state.event.topics)
	
			// eventDays.value = response?.data?.data?.[0]?.days.map(day=> ({ 'date': isoDateformat(day.date),'tracks':[] }))
			
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}

const generateTrackCss = ()=>{
	var style = document.createElement('style');
	document.head.appendChild(style);
	for(let i=0;i < splitDays.value.length;i++){
		console.log('class',splitDays.value[i].class);
		style.sheet.insertRule(`.${splitDays.value[i].class} .vuecal__event::after { background-color: ${splitDays.value[i].color}; }`, 0)
	}
}




</script>

				<style>
				/* date selector */
				.tool-bar{box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;padding: 12px 18px;border-radius: 5px;
				display: flex;align-items: center;justify-content: space-between;}
				.tool-bar h3{display: inline-block;}

				/* .wrap {
					display: flex;
				} */

				.left {
					margin-right: 10px;
				}

				.content {
					flex-grow: 1;
				}

				.navigator_default_busy.navigator_default_cell {
					border-bottom: 4px solid #ee4f2ecc;
					box-sizing: border-box;
				}
				/* .vuecal__cell-content{background-color:#f075aa29;} */
				.vuecal__cell--disabled{text-decoration: line-through;color: #bbb;background-color:gainsboro;}   
				.vuecal__no-event {display: none;} 
				.vuecal__flex{position: relative;border-right: 1px solid #d5d5d5;}
				.vuecal__flex:nth-last-child(1){position: relative;border-right: unset;}
				/* .vuecal__time-column {background-color: #fde9f1;} */
				.vuecal__time-column .vuecal__time-cell {font-weight: 600;}
				.vuecal__event {position: relative; color: #000;font-weight: 600;background-color: #ffff;border-radius: 12px;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;padding:12px 13px 13px 20px;}
				
			    .vuecal__event::after{position:absolute;content: '';width: 12px;height: 100%;left: 0;top:0;}  
				
				.blue-after{position: relative;}
				.vuecal__event-title,.vuecal__event-time{text-align: left;}
				.vuecal__event-time{font-size: 14px;color: gray;}
				.vuecal__event{position: relative;}
				.vuecal__event-option{position:absolute;right: 17px;top:3px;}
				.add-track{position: fixed;
					right: 22px;
					top: 344px;
					padding: 1px 16px 0px 1px;
					background-color: #007cba;
					z-index: 1040;
					display: flex;
					align-items: center;
					border-radius: 16px 1px 16px 16px;
					color: #fff;
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}
				.add-track span{color: #fff;
					font-size: 28px;
					padding: 0px 4px 1px 11px;}
					.option-container > .fa-ellipsis-vertical,.event-option-container > .fa-ellipsis-vertical{width: 18px;position: relative;}
                  .ellipsis-icon{width: 28px;
					height: 28px;
					position: relative;
					color: black;
					font-weight: 800;
					font-size: 20px;}
				 .close-icon{    width: 18px;
					color: gray;
					position: absolute;
					right: 6px;
					top: 2px;
					font-size: 14px;
					}	
				.option-dropdown,.event-option-dropdown{
					display:none;
					position: absolute;
					background: white;
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
					padding: 16px 10px 10px;
					border-radius: 9px;
					top:0;
					z-index: 1040;
					}
					.option-dropdown{width: 100px;}
					.event-option-dropdown{width: 100px;right:0px;}	
				.menu-item{font-size: 14px;text-align:left;}
				.menu-item .fa-pen-to-square, .menu-item .fa-trash{margin-right: 9px;}
				.menu-item .fa-trash{color:red;}	
				.disable-option,.disable-event-option{position: absolute;
					right: 7px;
					top: 8px;
					color: gray;}
				.pointer{cursor: pointer;}
                .event-container{display: flex;}
				.color-bar{width: 12px;min-height: 100%;background-color: transparent;}
                .error-message{
				position: relative;	
				border: 1px solid red;
				color: red;
				padding: 7px;
				border-radius: 5px;
				width: 46px;
				text-align: center;
				margin-right:12px;
				display: flex;
				align-items: center;
				justify-content: center;}
				.schedule-action{display: flex;
				justify-content: space-between;
				margin-top: 21px;}
				.save-as-draft,.publish-event{height: 43px;font-weight: 800;}
				.save-as-draft:hover,.publish-event:hover{box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;}
				.save-as-draft{border: 1px solid #007CBA;
				color: #007CBA;
				border-radius: 7px;
				padding: 9px 10px;
				}
				.publish-event{background-color: #007CBA;color: white;border: 1px solid #007CBA;
				color: white;
				border-radius: 7px;
				padding: 9px 10px;}

				.vuecal__bg{height: 450px;overflow-y:auto;}
				.vuecal__bg::-webkit-scrollbar-track
				{
					-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
					border-radius: 10px;
					background-color: #F5F5F5;
				}
				.vuecal__bg::-webkit-scrollbar
				{
					width: 7px;
					background-color: #F5F5F5;
				}
				.vuecal__bg::-webkit-scrollbar-thumb
				{
					border-radius: 10px;
					-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
					background-color: #C9C9C9;
				}
                .error-dialog{position: absolute;
				right: 0px;
				bottom: 55px;
				z-index: 1030;
				background: white;
				min-width: 369px;
				height: -moz-max-content;
				height: max-content;
				border: 1px solid;
				border-radius: 2px;
				text-align: left;
				padding: 6px;}
				.close-error-dialog{position: absolute;right: 9px;
					color: black;}
		   .error-dialog ul{list-style: none;
    margin-left: 21px;
    color: black;
    font-weight: 700;}
	.vuecal__body{min-width: 85% !important;background-color: #fde9f1;}
	.vuecal__split-days-headers .day-split-header{ min-width: 250px !important;flex-grow: unset;flex-basis: unset;}
	.vuecal__cells.day-view .vuecal__cell{flex:unset}
	.vuecal__cell-split{ width: 250px;}


	/* testing code */
	.vuecal--overflow-x.vuecal--day-view .vuecal__cells{width: max-content;border: 2px solid white;}
				</style>
