<template>
  <DetailDrawer
    :data="memberView.data"
    :full-name="memberView.fullName"
    :type="type"
    @activate-member-confirmation="activateMemberConfirmation"
    @deactivate-member-confirmation="deactivateMemberConfirmation"
    @block-member-confirmation="blockMemberConfirmation"
    @approve-member-confirmation="approveMemberConfirmation"
    @reject-member-confirmation="rejectMemberConfirmation"
    @delete-member-confirmation="deleteMemberConfirmation"
  />
  
  <div class="tab-heading-and-action">
    <h3>{{ listHeading(type) }}  </h3>
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
      </div>
      
      <fa
        icon="filter"
        class="filter-btn"
        @click="openFilter"
      />
         
      <div
        v-if="showFilter === true"
        class="filtercard"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="closeFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div
            class="reset-btn pointer"
            @click="resetFilter"
          >
            RESET
          </div>
        </div>
         
        
        <select
          id="BusinessCategory"
          v-model="selectedBusinessCategory"
          name="BusinessCategory"
          @change="selectBusinessCategory"
        >
          <option value="">
            Business Category
          </option>
          <option value="Consumer Services">
            Consumer Services
          </option>
          <option value="Health Care">
            Health Care
          </option>
        </select>
  
        <select
          id="gender"
          v-model="selectedGender"
          name="gender"
        >
          <option value="">
            Gender
          </option>
          <option value="Male">
            Male
          </option>
          <option value="FeMale">
            FeMale
          </option>
        </select>
      </div>
    </div>
  </div>
   
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    v-bind="refresh"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
  <template #[`item.businessCategory`]="{ item }">
      <v-tooltip
        v-if="item.businessCategory!='' && item.businessCategory.length > 8 "
        :text="item.businessCategory"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(item.businessCategory,10) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.businessCategory }}
      </div>
    </template> 
    
    <template #[`item.action`]="{ item }">


      <v-tooltip text="View">
        <template #activator="{ props }">
          <fa 
            v-bind="props"
            icon="eye"
            class="pointer"
            @click="viewMember(item.view)"
          />
        </template>
      </v-tooltip>

      <fa
        v-if="type == 'activeMembers'"
        icon="bell"
        class="pointer"
      />
      <fa
        v-if="type == 'activeMembers'"
        icon="circle-xmark"
        class="pointer"
        @click="deactivateMemberConfirmation(item.action)"
      />

      <fa
        v-if="type == 'registrationPending'"
        icon="stopwatch"
        class="pointer"
      />
      <fa
        v-if="type == 'registrationPending' || type == 'deactivatedMembers'"
        icon="trash"
        class="pointer"
        :class="{ 'registration-pending-trash': type == 'registrationPending', 'deactivated-members-trash':type == 'deactivatedMembers'}"
        @click="deleteMemberConfirmation(item.action)"
      />

      <fa
        v-if="type == 'blocked'"
        icon="trash"
        class="pointer"
        :class="{ 'blocked-trash': type == 'blocked'}"
        @click="deleteRejectedMemberConfirmation(item.action)"
      />


      <fa
        v-if="type == 'deactivatedMembers' || type == 'blocked'"
        icon="circle-check"
        class="pointer"
        :class="{ 'deactivated-members-circle-check':type == 'deactivatedMembers','blocked-circle-check':type == 'blocked'}"
        @click="activateMemberConfirmation(item.action)"
      />
    </template>
  </v-data-table-server>
  
  <!-- activate member -->
  <ConfirmationDialog
    :visible="activateDialog"
    :title-icon="'check'"
    :body="'Are you sure you want to activate this member? This action cannot be undone.'"
    :confirm-text="'Activate'"
    :button-bg="'success'"
    @cancel="activateDialog = false"
    @confirm="activateMember"
  />
  <!-- activate member -->

  <!-- approve member -->
  <ConfirmationDialog
    :visible="approveDialog"
    :title-icon="'check'"
    :body="'Are you sure you want to approve this member? This action cannot be undone..'"
    :confirm-text="'Approve'"
    :button-bg="'info'"
    @cancel="approveDialog = false"
    @confirm="approveMember"
  />
  <!-- approve member -->

  <!-- block member -->
  <ConfirmationDialog
    :visible="blockDialog"
    :body="'Are you sure you want to block this member? This action cannot be undone.'"
    :confirm-text="'Block'"
    :button-bg="'danger'"
    @cancel="blockDialog = false"
    @confirm="blockMember"
  />
  <!-- block member -->

  <!-- delete member -->
  <ConfirmationDialog
    :visible="deleteDialog"
    :body="'Are you sure you want to delete this member? This action cannot be undone.'"
    :confirm-text="'Delete'"
    :button-bg="'danger'"
    @cancel="deleteDialog = false"
    @confirm="deleteMember"
  />
  <!-- delete member -->

  <!-- delete rejected member -->
  <ConfirmationDialog
    :visible="deleteRejectedMemberDialog"
    :body="'Are you sure you want to delete this Rejected member? This action cannot be undone.'"
    :confirm-text="'Delete'"
    :button-bg="'danger'"
    @cancel="deleteRejectedMemberDialog = false"
    @confirm="deleteRejectedMember"
  />
  <!-- delete member -->
  


  

  <!-- deactivate member -->
  <ConfirmationDialog
    :visible="deactivateDialog"
    :body="'Are you sure you want to deactivate this member? This action cannot be undone.'"
    :confirm-text="'Deactivate'"
    :button-bg="'danger'"
    @cancel="deactivateDialog = false"
    @confirm="deactivateReasonDialog = true"
  />
  <!-- deactivate member -->

  <!-- deactivate reason -->
  <ReasonDialog
    :visible="deactivateReasonDialog"
    :title="'Reason for Deactivate'"
    :confirm-text="'Submit'"
    :button-bg="'danger'"
    @cancel="deactivateReasonDialog = false"
    @submit="submitDeactivateReason"
  />
  <!-- deactivate reason -->


  
  

  
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
  >
    {{ text }}
  
    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
  
  
  
  
  
<script setup>
import axios from 'axios';
import DetailDrawer  from '@/components/members/DetailDrawer.vue';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue'
import ReasonDialog from '@/components/ui/ReasonDialog.vue'
import {ref,reactive,provide,watch,onBeforeMount} from 'vue'
import { useStore } from 'vuex';
import { truncateString, formattedDate, memberActivity, capitalizeString, listHeading } from '@/helper/index.js';
    
const store = useStore();   
const props = defineProps({
	type:{
		type:String,
		default:''
	}
})

let openMemberDetail = reactive({'status':false,'id':''});
provide('openMemberDetail', openMemberDetail)

const itemsPerPage = ref(5);
const offset  = ref(0);


const dynamicHeaders = reactive([
	{ title: 'Full Name', value: 'fullName', activeMembers:true, approvalPending:true, registrationPending:true, deactivatedMembers:true, blocked:true },
	{ title: 'Phone', value: 'phoneNumber', activeMembers:true, approvalPending:true, registrationPending:true, deactivatedMembers:true, blocked:true },
	{ title: 'Business Category', value: 'businessCategory', activeMembers:true, approvalPending:true, registrationPending:true, deactivatedMembers:true, blocked:true },
	{ title: 'Chapter', value: 'chapter', activeMembers:true, approvalPending:true, registrationPending:true, deactivatedMembers:true, blocked:true },
	{ title: 'Member Since', value: 'memberSince', activeMembers:true, approvalPending:false, registrationPending:false, deactivatedMembers:true, blocked:true },
	{ title: 'Invited On', value: 'invitedOn', activeMembers:false, approvalPending:false, registrationPending:true, deactivatedMembers:false, blocked:false },
	{ title: 'Registered On', value: 'registeredOn', activeMembers:false, approvalPending:true, registrationPending:false, deactivatedMembers:false, blocked:false },
	{ title: 'Last Activity', value: 'lastActivity', activeMembers:true, approvalPending:false, registrationPending:true, deactivatedMembers:false, blocked:false },
	{ title: 'Deactivated On', value: 'deactivatedOn', activeMembers:false, approvalPending:false, registrationPending:false, deactivatedMembers:true, blocked:false },
	{ title: 'Rejected/Blocked On', value: 'blockedOn', activeMembers:false, approvalPending:false, registrationPending:false, deactivatedMembers:false, blocked:true },
	{ title: 'Action', value: 'action', activeMembers:true, approvalPending:true, registrationPending:true, deactivatedMembers:true, blocked:true }
]);

const headers = dynamicHeaders.filter((x)=>x[props.type] == true)
console.log('props.type',props.type)



const serverItems = ref([]);
const loading = ref(true);
const refresh = ref(false);
const totalItems = ref(0);
const memberId = ref('');
const memberView = reactive({
	fullName:'',
	data:{}
})

const showFilter = ref(false);
const selectedBusinessCategory = ref('');
const selectedGender = ref('');
const name =  ref('');
const search =  ref('');
const snackbar = ref(false);
const text =  ref('');
const timeout = ref(2000);  

const activateDialog =  ref(false);
const approveDialog =  ref(false);
const deactivateDialog =  ref(false);
const deactivateReasonDialog = ref(false);
const blockDialog = ref(false);
const deleteDialog = ref(false);
const deleteRejectedMemberDialog = ref(false);
const tableHeight = ref(0);
   


// confirmation dialog

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});
 
watch(selectedBusinessCategory, () => {
	search.value = String(Date.now())
});

watch(selectedGender, () => {
	search.value = String(Date.now())
});


onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
})


const loadItems = async({ page, itemsPerPage, sortBy }) =>{
	offset.value = (page - 1) * itemsPerPage
	loading.value = true
              
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/user?${memberStatusQuery(props.type)}&limit=${itemsPerPage}&offset=${offset.value}`,{
			params:{
				value:name.value,
				businessCategory: selectedBusinessCategory.value,
				gender: selectedGender.value}, 
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			serverItems.value = []
			console.log('response.data.title',response.data);
			// serverItems = response.data.data;
			totalItems.value = response.data.totalCount;
			loading.value = false
            
			let memberData = response.data.data;
			memberData.forEach((item) => {
  
            
				let memberList = {'fullName':fullNameGenerator(item),
					'phoneNumber':`${item?.phone?.countryCode ?? ''} ${item?.phone?.number ?? 'N/A'}`,
					'businessCategory': item?.industry || '',
					...(item.chapterDetail && { chapter: item.chapterDetail.chapter }),
					'action':item?._id,
					'view':item}
         
				switch (props.type) {
				case 'activeMembers':
					memberList.memberSince = item?.createdAt?formattedDate(item?.createdAt):'';
					memberList.lastActivity = item?.loginDate?memberActivity(item?.loginDate?item?.loginDate:'',item?.updatedAt):'';
					break;
				case 'approvalPending':
					memberList.registeredOn = item?.createdAt?formattedDate(item?.createdAt):'';
					break;
				case 'registrationPending':
					memberList.invitedOn = item?.createdAt?formattedDate(item.createdAt) : '';
					memberList.lastActivity = item?.loginDate?memberActivity(item?.loginDate?item?.loginDate:'',item?.updatedAt):'';
					break;
				case 'deactivatedMembers':
					memberList.memberSince = item?.createdAt?formattedDate(item?.createdAt):'';
					memberList.deactivatedOn = item?.updatedAt?formattedDate(item.updatedAt):'' ;
					break;
				case 'blocked':
					memberList.memberSince = item?.createdAt?formattedDate(item?.createdAt):'';    
					memberList.blockedOn = item?.updatedAt?formattedDate(item.updatedAt):'';
					break;
				default:
					memberList.memberSince = item?.createdAt?formattedDate(item?.createdAt):'';
					memberList.lastActivity = item?.loginDate?memberActivity(item?.loginDate?item?.loginDate:'',item?.updatedAt):'';
          
				}
            
				serverItems.value.push(memberList)
			})
		})
		.catch((error)=>{
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}
							
				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
  
          
}

const viewMember = (data) => {
	console.log("data",data);
	memberView.data = data;
	if(data?.title){
		memberView.fullName = data?.title +' ';
	}

	if(data?.firstName){
		memberView.fullName+= data?.firstName;
	}

	if(data?.middleName){
		memberView.fullName+=' '+data?.middleName;
	}

	if(data?.lastName){
		memberView.fullName+=' '+data?.lastName;
	}
	console.log('memberView.fullName',memberView.fullName);
	openMemberDetail.status = true;

	
}


const activateMemberConfirmation = (id) =>{
	memberId.value = id;
	activateDialog.value = true;
}

const	activateMember = () =>{
	axios
		.put(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
		     {'isActive':true},
			    {headers:{
			      'Authorization': `Bearer ${store.state.authentication.token}`,
			    }})
		.then((response) => {
			  if(response.status === 200){
			    activateDialog.value = false;
			    openMemberDetail.status = false;
			    search.value = String(Date.now());
			    snackbar.value = true;
			    text.value = "Activated Successfully"; 
			    
			  }
			  })
}

const deactivateMemberConfirmation = (item) =>{
	memberId.value = item;
	deactivateDialog.value = true;
}



const deactivateMember = (reason) =>{
	
	axios
		.put(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
			{'isActive':false,
				'reason':reason
			},
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log(" deactivate response",response);
			if(response.status === 200){
				deactivateDialog.value = false;
				openMemberDetail.status = false;
            
				// loading = true
				search.value = String(Date.now())
				snackbar.value = true;
				deactivateReasonDialog.value = false;
           
            
  
				text.value = "Deactivated Successfully"; 
				setTimeout(() => {
					loading.value = false
				}, 2000)
			}
		})
}        


   
const blockMemberConfirmation = (item) =>{
	memberId.value = item;
	blockDialog.value = true;
}

const blockMember = ()=>{
	console.log(memberId.value);
	axios
		.put(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
			{'memberStatus':'blocked'},
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log(" deactivate response",response);
			if(response.status === 200){
              
				blockDialog.value = false;
				openMemberDetail.status = false;
				search.value = String(Date.now())
				snackbar.value = true;
				text.value = "Blocked Successfully"; 
                          
				setTimeout(() => {
					loading.value = false
				}, 2000)
			}
		})
}




const rejectMemberConfirmation = (item)=>{
	memberId.value = item;
	console.log(item);
}

const deleteMemberConfirmation = (item)=>{
	memberId.value = item;
	deleteDialog.value = true;
}

const	deleteMember = () =>{
	axios
		.delete(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log(" delete response",response);
			if(response.status === 200){
				deleteDialog.value = false;
				openMemberDetail.status = false;
				search.value = String(Date.now());
				snackbar.value =true;
				text.value = "Deleted Successfully"; 
						
				setTimeout(() => {
					loading.value = false
				}, 2000)
			}
		})
}

const	deleteRejectedMember = () =>{
	axios
		.delete(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log(" delete response",response);
			if(response.status === 200){
				deleteRejectedMemberDialog.value = false;
				search.value = String(Date.now());
				snackbar.value =true;
				text.value = "Deleted Successfully"; 
						
				setTimeout(() => {
					loading.value = false
				}, 2000)
			}
		})
}



const deleteRejectedMemberConfirmation = (item)=>{
	memberId.value = item;
	deleteRejectedMemberDialog.value = true;
}

const approveMemberConfirmation = (item) =>{
	memberId.value = item;
	approveDialog.value = true;
}

const	approveMember = ()=>{
	axios
		.put(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
			{'isActive':true},
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				approveDialog.value = false;
				openMemberDetail.status = false;
				search.value = String(Date.now());
            
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Approved Successfully',
					color:'success',
					timeout:2000});
						
			}
		})
}

const submitDeactivateReason = (e) =>{
	deactivateMember(e.target.reason.value)
}

const openFilter = () =>{
	showFilter.value = true;
}

const closeFilter = () =>{
	showFilter.value = false;
}
          
const resetFilter = () =>{
	selectedBusinessCategory.value = '';
	selectedGender.value = '';
}


const memberStatusQuery = (type) =>{
	switch (type) {
	case 'activeMembers':
		return 'active=yes'
	case 'approvalPending':
		return 'memberStatus=approvalPending'
	case 'registrationPending':
		return 'memberStatus=registrationPending'  
	case 'deactivatedMembers':
		return 'active=no'
	case 'blocked':
		return 'memberStatus=blocked'      
	default:
		return 'active=yes'
	}
} 


const fullNameGenerator = (data)=>{

	let fullName ='';
	if(data?.firstName){
		fullName+= data?.firstName;
	}

	if(data?.middleName){
		fullName+=' '+data?.middleName;
	}

	if(data?.lastName){
		fullName+=' '+data?.lastName;
	}
	return fullName;
}



  
  
</script>
  
  <style scoped>
  .tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
  .tab-heading-and-action h3{font-size: 18px;}
  .tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
  
  
  .search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
   .search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
   .search-container input{outline: unset;}
  
   .filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
  .filter-btn{position: relative;cursor: pointer;}
  .filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
  .filtercard select{border: 1px solid #B2B2B2;
      padding: 6px;
      margin-bottom: 12px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:#B2B2B2;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  :deep() .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
      /* :deep() .filtercard > input{border: 1px solid gainsboro;
      border-radius: 5px;
      padding: 6px;
      margin-bottom: 12px;}      */
  .filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
  .reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;}
  .filter-close-btn{width: max-content;
      margin-left: auto;
      display: block;
     cursor: pointer;}
  
  .v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
  .v-table :deep() thead:nth-child(7){background-color: orange;}
  /* .v-table :deep() thead tr > th{width: 120px;} */
  .v-table :deep() tbody tr > td{text-align: left;}
  .v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
  .v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
  .v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
  .v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
  .v-table :deep() tbody tr > td .fa-bell{color: #353535;margin-right: 5px;}
  .v-table :deep() tbody tr > td .fa-circle-xmark{color:#EC1B1B;}
  .pointer{cursor: pointer;}
  .registration-pending-trash, .deactivated-members-trash, .blocked-trash{color:#EC1B1B;}
  .deactivated-members-circle-check,.blocked-circle-check{color: #8BC34A;}
  .registration-pending-trash,.deactivated-members-circle-check,.blocked-circle-check{margin-left:5px;}
  
  .delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
  .delete-dialog input{ border: 1px solid gray;
      border-radius: 4px;
      height: 36px;
      margin: 12px 24px 0;
      background: white;}
   .delete-dialog .err-msg{margin-left: 24px;
      color: red;
      font-size: 14px;}   
  .delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
  .v-card-text h3{display: flex;align-items: center;}
  .v-card-text .fa-trash-can{color: #EC1B1B;
      background-color: #FEE4E2;
      padding: 9px;
      border-radius: 50%;
      font-size: 15px;
      margin-right: 12px;}
  .delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
  .delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
  .delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
  .delete-action button{padding: 6px 13px;
      border-radius: 9px;
      border: 1px solid;}
  
   .view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;z-index:1040;width:100%;height: 100vh;}
   .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
  .view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
  .view-modal-action .fa-arrow-right-long{cursor: pointer;}
  .view-modal-action h3{margin-left: 18px;}
  .view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
  .member-details-content{position:sticky;text-align: left;}
  .member-details-content h5{color: #858585;font-weight: 500;
      font-size: 16px;}
  .member-details-content p{color: #242426;font-weight: 600;
      margin-bottom: 23px;}
   .member-details-content button{
      border: 1px solid #EC1B1B;
      color: #EC1B1B;
      padding: 8px 10px;
      display: block;
      margin: 0 auto 12px;
      border-radius: 5px;
      font-weight: 600;
      min-width: 200px;}   
  </style>
  
  