<template>
  <AdminPanel>
    <!-- <v-container> -->
    <div
      v-for="(item, i) in draggables"
      :key="i"
      class="external-event"
      draggable="true"
      @dragstart="onEventDragStart($event, item)"
    >
      <strong>{{ item.title }}</strong>
      ({{ item.duration ? `${item.duration} min` : 'no duration' }})
      <div>{{ item.content }}</div>
    </div>	

    <vue-cal
      small
     
      :time-from="8 * 60"
      :time-step="30"
      class="vuecal--blue-theme"
	  :disable-views="['years', 'year', 'month','day']"
      :events="events"
      :split-days="splitDays"
      :sticky-split-labels="stickySplitLabels"
      :min-cell-width="minCellWidth"
      :min-split-width="minSplitWidth"
      editable-events
      @event-drop="onEventDrop"
    />
    <!-- </v-container> -->
  </AdminPanel>
</template>


<script setup>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import { useStore } from 'vuex';
import {ref, onMounted} from 'vue';
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'



const store = useStore(); 

const stickySplitLabels = ref(false);
const minCellWidth = ref(400);
const minSplitWidth = ref(0);

const draggables = ref([
	{
		// The id (or however you name it), will help you find which event to delete
		// from the callback triggered on drop into Vue Cal.
		id: 1,
		title: 'Ext. Event 1',
		content: 'content 1',
		duration: 60
	},
	{
		id: 2,
		title: 'Ext. Event 2',
		content: 'content 2',
		duration: 30
	},
	{
		id: 3,
		title: 'Ext. Event 3',
		content: 'content 3'
		// No defined duration here: will default to 2 hours.
	}
])


const splitDays = ref([
	// The id property is added automatically if none (starting from 1), but you can set a custom one.
	// If you need to toggle the splits, you must set the id explicitly.
	{ id: 1, class: 'Track 1', label: 'Track 1' },
	{ id: 2, class: 'Track 2', label: 'Track 2'},
	{ id: 3, class: 'Track 3', label: 'Track 3' }
])

const events = ref([
	{
		start: '2024-06-03 09:35',
		end: '2024-06-03 10:30',
		title: 'Doctor appointment',
		content: '<i class="icon material-icons">local_hospital</i>',
		class: 'health',
		split: 1 // Has to match the id of the split you have set (or integers if none).
	},
	
])

const onEventDragStart  = (e, draggable)=>{
  console.log('onEventDragStar calender vie',e);
	// Passing the event's data to Vue Cal through the DataTransfer object.
	e.dataTransfer.setData('event', JSON.stringify(draggable))
	e.dataTransfer.setData('cursor-grab-at', e.offsetY)
}

const onEventDrop  = ({ event, originalEvent, external })=>{
	// If the event is external, delete it from the data source on drop into Vue Cal.
	// If the event comes from another Vue Cal instance, it will be deleted automatically in there.
	if (external) {
		const extEventToDeletePos = draggables.value.findIndex(item => item.id === originalEvent.id)
		if (extEventToDeletePos > -1) draggables.value.splice(extEventToDeletePos, 1)
	}
}
	
onMounted(() => {
	console.log("DayPilot")
	
});




</script>

<style scoped>
.schedule-container{display:flex;padding: 12px;}
.topic-section{flex-basis: 30%;
    height: 100vh;
    margin-right: 12px;
    border-radius: 0 12px 0 0;
    margin-left: -15px;
    margin-top: -10px;
    border: 1px solid #CACACA;
    border-bottom: 0;
    border-left: 0;
    }
    .topic-action{border-bottom: 1px solid #9E9E9E;
    margin: 10px;display: flex;justify-content: space-between;align-items: center;
    padding-bottom: 10px;}  
    .add-event{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-event .fa-plus{margin-right: 8px;}


.schedule-grid{flex-basis: 70%;}



/* date selector */
.tool-bar{box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;padding: 12px 18px;border-radius: 5px;
display: flex;align-items: center;justify-content: space-between;}
.tool-bar h3{display: inline-block;}
.date-selector{
	position: relative;
	min-width: 200px;
    height: 37px;
    background-color: #f0f0f0;
    overflow: hidden;
    border-radius: 5px;}
.date{position: absolute;
    left: 0;
    right: 0;
    min-width: 200px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;}	

.nav-prev,.nav-right{
	position: absolute;
     width: max-content;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;padding: 0 12px;cursor: pointer;
	z-index: 1020;}
    
	.nav-prev{left: 0;}
	.nav-right{right: 0;}
.wrap {
    display: flex;
}

.left {
    margin-right: 10px;
}

.content {
    flex-grow: 1;
}

.navigator_default_busy.navigator_default_cell {
    border-bottom: 4px solid #ee4f2ecc;
    box-sizing: border-box;
}

</style>



